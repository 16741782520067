/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PagedMetaResponse,
    PagedMetaResponseFromJSON,
    PagedMetaResponseFromJSONTyped,
    PagedMetaResponseToJSON,
} from './PagedMetaResponse';

/**
 * A paginated list of available encoders
 * @export
 * @interface EncoderListResponse
 */
export interface EncoderListResponse {
    /**
     * 
     * @type {PagedMetaResponse}
     * @memberof EncoderListResponse
     */
    meta: PagedMetaResponse;
    /**
     * The paginated encoders
     * @type {Array<string>}
     * @memberof EncoderListResponse
     */
    data: Array<string>;
}

export function EncoderListResponseFromJSON(json: any): EncoderListResponse {
    return EncoderListResponseFromJSONTyped(json, false);
}

export function EncoderListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EncoderListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': PagedMetaResponseFromJSON(json['meta']),
        'data': json['data'],
    };
}

export function EncoderListResponseToJSON(value?: EncoderListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': PagedMetaResponseToJSON(value.meta),
        'data': value.data,
    };
}

