/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatasetStatusEnum,
    DatasetStatusEnumFromJSON,
    DatasetStatusEnumFromJSONTyped,
    DatasetStatusEnumToJSON,
} from './DatasetStatusEnum';
import {
    StorageTypeEnum,
    StorageTypeEnumFromJSON,
    StorageTypeEnumFromJSONTyped,
    StorageTypeEnumToJSON,
} from './StorageTypeEnum';

/**
 * A set of images and videos to query
 * @export
 * @interface DatasetResponse
 */
export interface DatasetResponse {
    /**
     * The user that created the resource
     * @type {string}
     * @memberof DatasetResponse
     */
    createdUserId: string;
    /**
     * The created datetime of the resource
     * @type {Date}
     * @memberof DatasetResponse
     */
    createdDt: string;
    /**
     * The user that last updated the resource
     * @type {string}
     * @memberof DatasetResponse
     */
    updatedUserId: string;
    /**
     * The datetime the resource was last updated
     * @type {Date}
     * @memberof DatasetResponse
     */
    updatedDt: string;
    /**
     * The name of the dataset
     * @type {string}
     * @memberof DatasetResponse
     */
    name: string;
    /**
     * The description of the dataset
     * @type {string}
     * @memberof DatasetResponse
     */
    description?: string | null;
    /**
     * The unique identifier for the dataset
     * @type {string}
     * @memberof DatasetResponse
     */
    datasetId: string;
    /**
     * 
     * @type {DatasetStatusEnum}
     * @memberof DatasetResponse
     */
    status: DatasetStatusEnum;
    /**
     * 
     * @type {StorageTypeEnum}
     * @memberof DatasetResponse
     */
    storageType?: StorageTypeEnum;
    /**
     * True if OCR (optical character recognition) is enabled for all assets in this dataset
     * @type {boolean}
     * @memberof DatasetResponse
     */
    ocr?: boolean | null;
    /**
     * True if the dataset is in a state in which it can be deleted
     * @type {boolean}
     * @memberof DatasetResponse
     */
    isDeletable?: boolean;
}

export function DatasetResponseFromJSON(json: any): DatasetResponse {
    return DatasetResponseFromJSONTyped(json, false);
}

export function DatasetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdUserId': json['createdUserId'],
        'createdDt': json['createdDt'],
        'updatedUserId': json['updatedUserId'],
        'updatedDt': json['updatedDt'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'datasetId': json['datasetId'],
        'status': DatasetStatusEnumFromJSON(json['status']),
        'storageType': !exists(json, 'storageType') ? undefined : StorageTypeEnumFromJSON(json['storageType']),
        'ocr': !exists(json, 'ocr') ? undefined : json['ocr'],
        'isDeletable': !exists(json, 'isDeletable') ? undefined : json['isDeletable'],
    };
}

export function DatasetResponseToJSON(value?: DatasetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdUserId': value.createdUserId,
        'createdDt': value.createdDt,
        'updatedUserId': value.updatedUserId,
        'updatedDt': value.updatedDt,
        'name': value.name,
        'description': value.description,
        'datasetId': value.datasetId,
        'status': DatasetStatusEnumToJSON(value.status),
        'storageType': StorageTypeEnumToJSON(value.storageType),
        'ocr': value.ocr,
        'isDeletable': value.isDeletable,
    };
}

