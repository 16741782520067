import React, { useEffect } from 'react';
import {
  ArrowDownOnSquareStackIcon,
  CircleStackIcon,
} from '@heroicons/react/24/solid';
import MenuButton, { MenuButtonItem } from 'components/MenuButton';
import Button from 'components/Button';
import { FullVersionResponse } from 'api/generated';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { getConceptsQueryKey } from 'queries/concepts';
import { toast } from 'react-toastify';
import { toggles } from 'config';
import { useRefineSidebarContext } from 'context/RefineSidebarContext';
import { ConceptActionState, TabsValues, LabelCandidateStatus } from '../types';
import {
  useDeleteConceptMutation,
  useExportConceptVersionAsCsv,
} from '../queries';

interface ConceptDetailsTitleProps {
  conceptVersionId: string | 'latest';
  isConceptUnlabeled: boolean;
  version: FullVersionResponse;
  actionState: ConceptActionState;
  setActionState: (actionState: ConceptActionState) => void;
  conceptId: string;
  setActiveTab: (activeTab: TabsValues) => void;
  labelCandidatesStatus: LabelCandidateStatus;
  setLabelCandidatesStatus: (
    labelCandidatesStatus: LabelCandidateStatus,
  ) => void;
  setHasFetchingLoadingIndicator: (
    hasFetchingLoadingIndicator: boolean,
  ) => void;
  disabled?: boolean;
}

const ConceptDetailsTitle: React.FC<ConceptDetailsTitleProps> = ({
  actionState,
  conceptId,
  conceptVersionId,
  disabled,
  isConceptUnlabeled,
  labelCandidatesStatus,
  setActionState,
  setActiveTab,
  setLabelCandidatesStatus,
  setHasFetchingLoadingIndicator,
  version,
}) => {
  const { mutate: deleteConcept } = useDeleteConceptMutation(conceptId);
  const { data: versionCsvBlob, refetch: fetchVersionCsvBlob } =
    useExportConceptVersionAsCsv(conceptVersionId);
  const { setView } = useRefineSidebarContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isContinueLabelingActionDisabled = Boolean(
    version.jobStatus === 'Running' ||
      !version.isQueryable ||
      actionState === ConceptActionState.Label,
  );
  const datasetUrl = `${window.location.origin}/datasets/${version.dataset.id}`;
  const datasetPathname = new URL(datasetUrl).pathname;
  const items: MenuButtonItem[][] = [];
  if (conceptVersionId === '') {
    items.push([
      {
        id: 'save',
        name: 'Save as new version',
        icon: ArrowDownOnSquareStackIcon,
        onClick: () => navigate(`/concepts/${conceptId}/create`),
      },
    ]);
  } else {
    items.push([
      {
        id: 'latest',
        name: <strong>latest</strong>,
        onClick: () => navigate(`/concepts/${conceptId}/versions/latest`),
      },
    ]);
  }

  const headerMenuOptions = [
    {
      id: 'continue-labeling',
      name: 'Continue labeling',
      onClick: () => {
        setActiveTab(TabsValues.Labels);
        setView('new-label');
        setHasFetchingLoadingIndicator(true);
        setActionState(ConceptActionState.Label);
      },
      disabled: isContinueLabelingActionDisabled,
    },
    {
      id: 'export',
      name: 'Export as CSV',
      onClick: async () => {
        fetchVersionCsvBlob();
      },
    },
    {
      id: 'select-examples',
      name: 'Select examples',
      onClick: () => {
        if (labelCandidatesStatus !== 'none') {
          setLabelCandidatesStatus('none');
        }
        setActiveTab(TabsValues.Labels);
        setActionState(ConceptActionState.Add);
      },
      disabled: actionState === ConceptActionState.Add,
    },
    {
      id: 'delete',
      name: <span className="text-red-500">Delete concept</span>,
      onClick: async () => {
        deleteConcept(
          { conceptId },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(getConceptsQueryKey());
              toast.success(
                <span>
                  Successfully deleted{' '}
                  <span className="font-semibold">
                    {version?.concept?.name}
                  </span>
                </span>,
              );
              navigate('/concepts');
            },
          },
        );
      },
    },
  ];

  useEffect(() => {
    if (versionCsvBlob) {
      const binaryData: string[] = [];
      binaryData.push(versionCsvBlob);
      const objectUrl = window.URL.createObjectURL(
        new Blob(binaryData, { type: 'text/csv' }),
      );
      window.open(objectUrl, '_blank');
      window.URL.revokeObjectURL(objectUrl);
    }
  }, [versionCsvBlob]);
  return (
    <div className="mt-[-2rem] flex flex-col gap-y-2.5 font-normal">
      <div className="flex justify-between">
        <h1 className="inline font-bold">{version?.concept?.name}</h1>
        <MenuButton
          position="right"
          label="More"
          disabled={disabled}
          items={[
            isConceptUnlabeled
              ? headerMenuOptions.filter(
                  (action) =>
                    action.id !== 'continue-labeling' &&
                    action.id !== 'select-examples',
                )
              : headerMenuOptions,
          ]}
        />
      </div>
      <div>
        <Button
          className="p-4 w-auto"
          onClick={() => navigate(datasetPathname)}
          buttonStyle="secondary"
          padding="py-1"
          color="bg-slate-100 hover:bg-slate-200"
          icon={CircleStackIcon}
          size="small"
        >
          {version.dataset.name}
        </Button>
      </div>
      {toggles.versioning() && (
        <>
          <span className="mx-2">/</span>
          <MenuButton
            label={version?.name || ''}
            items={items}
            menuButtonClassName={{
              base: 'inline cursor-pointer rounded-md transition-all border group py-1',
              open: 'border-gray-300 pl-3 pr-2',
              closed:
                'hover:border-gray-300 border-transparent hover:pl-3 hover:pr-2',
            }}
            chevronClassName={{
              base: 'ml-1 mb-1 h-5 w-5 inline transition-all',
              open: 'opacity-100',
              closed: 'opacity-0 group-hover:opacity-100',
            }}
          />
        </>
      )}
    </div>
  );
};

ConceptDetailsTitle.defaultProps = {
  disabled: false,
};

export default ConceptDetailsTitle;
