import { ChevronDownIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button';
import DropdownInput from 'components/DropdownInput';
import { useOutsideClick } from 'hooks/useOutsideClick';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FilterItem } from 'api/generated';
import { MetadataFilter } from 'types/metadata';

interface CustomMetadataProps {
  executeSearch: (shouldRefetch: boolean) => void;
  metadataFilters: MetadataFilter[];
  metadataKeys: FilterItem[];
  setMetadataFilters: (metadataFilter: MetadataFilter[]) => void;
  isDisabled?: boolean;
}

const CustomMetadata: React.FC<CustomMetadataProps> = ({
  executeSearch,
  isDisabled,
  metadataFilters,
  metadataKeys,
  setMetadataFilters,
}) => {
  const [areOptionsOpen, setAreOptionsOpen] = useState<boolean>(false);
  const [activeOption, setActiveOption] = useState<MetadataFilter>({
    key: metadataKeys[0]?.key || '',
    value: '',
    operator: '==',
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const convertKeysToDropdownOptions = (keys) =>
    keys.map((key) => ({
      label: key?.key,
      value: key?.key,
      active: metadataFilters?.find((field) => field.key === key.key),
    }));

  const handleSelectConcepts = (option) => {
    setMetadataFilters([...metadataFilters, option]);
    executeSearch(true);
    setAreOptionsOpen(false);
  };

  const handleOutsideClick = () => {
    setActiveOption({
      key: '',
      value: '',
      operator: '==',
    });
    setAreOptionsOpen(false);
  };

  useOutsideClick(containerRef, handleOutsideClick);

  const isAddButtonDisabled = useMemo(() => {
    if (activeOption.key === '' || !activeOption.value.trim()) {
      return true;
    }
    const isKeyValuePairAlreadyCreated = metadataFilters.find(
      (filter) =>
        filter.key === activeOption.key && filter.value === activeOption.value,
    );
    return Boolean(isKeyValuePairAlreadyCreated);
  }, [activeOption, metadataFilters]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (isAddButtonDisabled) {
      return;
    }
    if (event.key === 'Enter') {
      handleSelectConcepts(activeOption);
    }
  };

  const dropdownOptions = convertKeysToDropdownOptions(metadataKeys);

  useEffect(() => {
    setActiveOption({
      ...activeOption,
      key: metadataKeys[0].key,
    });
  }, [metadataKeys]);

  return (
    <div className="relative" ref={containerRef}>
      <Button
        className="focus:bg-transparent focus:border-green-500 focus:text-slate-700"
        rightIcon={ChevronDownIcon}
        onClick={() => setAreOptionsOpen((prevState) => !prevState)}
        type="button"
        buttonStyle="secondary"
        size="small"
        disabled={isDisabled}
      >
        Custom metadata
      </Button>
      {areOptionsOpen && (
        <div className="flex items-center flex-col absolute top-9 left-0 shadow-md z-20 rounded-md bg-white border border-solid border-grey-500">
          <div className="p-2.5 bg-slate-100">
            <DropdownInput
              dropdownOptions={dropdownOptions}
              handleKeyPress={handleKeyPress}
              option={activeOption}
              setOption={setActiveOption}
            />
          </div>
          <div className="p-2.5 flex justify-end w-full border-t border-solid border-grey-500 border-t-1">
            <Button
              onClick={() => handleSelectConcepts(activeOption)}
              type="button"
              buttonStyle="secondary"
              size="small"
              disabled={isAddButtonDisabled}
            >
              Add filter
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

CustomMetadata.defaultProps = {
  isDisabled: false,
};

export default CustomMetadata;
