import { PencilIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

interface EditableTextBaseProps {
  id: string;
  value: string | undefined | null;
  placeholder?: string;
  updateValue: (value: string) => Promise<void>;
  textInputElement: React.ReactNode;
  textInputRef: React.MutableRefObject<any>;
  textDisplayClassName?: string;
  wrapperClassName?: string;
}

const EditableTextBase: React.FC<EditableTextBaseProps> =
  function EditableTextView({
    id,
    value,
    placeholder,
    updateValue: updateValueProp,
    textInputElement,
    textInputRef,
    textDisplayClassName,
    wrapperClassName,
  }) {
    const globalListeners = useRef<
      { event: string; callback: (event: any) => void }[]
    >([]);
    const [editState, setEditState] = useState<'closed' | 'open' | 'pending'>(
      'closed',
    );

    const buttonId = `${id}-btn`;

    useEffect(() => {
      if (editState === 'open') {
        textInputRef.current?.focus();
      }
    }, [editState]);

    const clearGlobalListeners = () => {
      globalListeners.current.forEach((listener) =>
        window.document.removeEventListener(listener.event, listener.callback),
      );
      globalListeners.current = [];
    };

    const updateValue = async () => {
      if (textInputRef.current) {
        const newValue = textInputRef.current?.value;
        setEditState('pending');
        await updateValueProp(newValue);
        setEditState('closed');
      }
    };

    const openTextEditor = () => {
      const keyUpListener = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          clearGlobalListeners();
          updateValue();
        } else if (event.key === 'Escape') {
          clearGlobalListeners();
          setEditState('closed');
        }
      };

      const clickOutsideListener = (event: MouseEvent) => {
        if (
          (event.target as any)?.id !== buttonId &&
          event.target !== textInputRef.current
        ) {
          clearGlobalListeners();
          setEditState('closed');
        }
      };

      setEditState('open');
      window.document.addEventListener('keyup', keyUpListener);
      window.document.addEventListener('click', clickOutsideListener);
      globalListeners.current.push({ event: 'keyup', callback: keyUpListener });
      globalListeners.current.push({
        event: 'click',
        callback: clickOutsideListener,
      });
    };

    const buttonClass = `inline-flex items-center group transition-all hover:outline hover:outline-1 hover:cursor-pointer outline-gray-300 outline-offset-8 rounded-sm p-0 text-sm max-w-full`;
    return (
      <div className={classNames(wrapperClassName || '', 'mt-[-1rem] pb-5')}>
        {editState === 'open' ? (
          textInputElement
        ) : (
          <button
            type="button"
            id={buttonId}
            className={buttonClass}
            onClick={() => openTextEditor()}
          >
            <span className={classNames(textDisplayClassName, 'text-left')}>
              {value || (
                <span className="italic pointer-events-none color-">
                  {placeholder}
                </span>
              )}
            </span>
            <PencilIcon
              className={classNames(
                value && 'group-hover:opacity-100 opacity-0',
                'fill-gray-400 h-5 w-5 inline ml-2 pointer-events-none',
              )}
            />
          </button>
        )}
      </div>
    );
  };

EditableTextBase.defaultProps = {
  placeholder: undefined,
  textDisplayClassName: '',
  wrapperClassName: '',
};

export default EditableTextBase;
