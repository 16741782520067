/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FilterItem,
    FilterItemFromJSON,
    FilterItemFromJSONTyped,
    FilterItemToJSON,
} from './FilterItem';
import {
    PagedMetaResponse,
    PagedMetaResponseFromJSON,
    PagedMetaResponseFromJSONTyped,
    PagedMetaResponseToJSON,
} from './PagedMetaResponse';

/**
 * Available filter columns
 * @export
 * @interface SearchFiltersResponse
 */
export interface SearchFiltersResponse {
    /**
     * 
     * @type {PagedMetaResponse}
     * @memberof SearchFiltersResponse
     */
    meta: PagedMetaResponse;
    /**
     * A metadata property to use to group the similarity search results
     * @type {Array<FilterItem>}
     * @memberof SearchFiltersResponse
     */
    data: Array<FilterItem>;
}

export function SearchFiltersResponseFromJSON(json: any): SearchFiltersResponse {
    return SearchFiltersResponseFromJSONTyped(json, false);
}

export function SearchFiltersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFiltersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': PagedMetaResponseFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(FilterItemFromJSON)),
    };
}

export function SearchFiltersResponseToJSON(value?: SearchFiltersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': PagedMetaResponseToJSON(value.meta),
        'data': ((value.data as Array<any>).map(FilterItemToJSON)),
    };
}

