import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import React, { Fragment, FunctionComponent, ReactElement } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export interface MenuButtonItem {
  id: string;
  name: string | ReactElement;
  onClick: () => void;
  element?: string;
  icon?: FunctionComponent<{ className?: string }>;
  disabled?: boolean;
}

interface MenuButtonProps {
  label: string;
  items: MenuButtonItem[][];
  position?: 'left' | 'right';
  disabled?: boolean;
  menuButtonClassName?: {
    base: string;
    open: string;
    closed: string;
  };
  chevronClassName?: {
    base: string;
    open: string;
    closed: string;
  };
}

const MenuButton: React.FunctionComponent<MenuButtonProps> =
  function MenuButton({
    disabled,
    items,
    label,
    menuButtonClassName,
    chevronClassName,
    position,
  }) {
    return (
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                className={classNames(
                  !menuButtonClassName &&
                    'inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 disabled:opacity-60',
                  !menuButtonClassName && !disabled && 'hover:bg-gray-50',
                  menuButtonClassName?.base,
                  open
                    ? menuButtonClassName?.open
                    : menuButtonClassName?.closed,
                )}
                disabled={disabled}
              >
                {label}
                <ChevronDownIcon
                  className={classNames(
                    !chevronClassName && '-mr-1 ml-2 h-5 w-5',
                    chevronClassName?.base,
                    open ? chevronClassName?.open : chevronClassName?.closed,
                  )}
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={classNames(
                  `origin-top-${position} absolute ${position}-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-30`,
                )}
              >
                {items.map((itemGroup) => (
                  <div className="py-1" key={itemGroup[0].id}>
                    {itemGroup.map((item) => (
                      // TODO: don't cast to string
                      <Menu.Item key={item.id}>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm w-full disabled:opacity-60',
                            )}
                            disabled={item.disabled}
                            onClick={item.onClick}
                          >
                            {item.icon && (
                              <item.icon
                                className={classNames(
                                  'mr-3 h-5 w-5 text-gray-400',
                                  item.disabled
                                    ? ''
                                    : 'group-hover:text-gray-500',
                                )}
                                aria-hidden="true"
                              />
                            )}
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    );
  };

MenuButton.defaultProps = {
  menuButtonClassName: undefined,
  chevronClassName: undefined,
  position: 'left',
  disabled: false,
};

export default MenuButton;
