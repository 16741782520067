import {
  AddAssetsToDatasetOperationRequest,
  CreateDatasetOperationRequest,
  CreateDynamicTagTableOperationRequest,
  DatasetApi,
  DeleteDatasetRequest,
  GetDatasetsRequest,
  UpdateDatasetOperationRequest,
  ValidateDatasetNameOperationRequest,
} from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import { QueryClient, useIsFetching, useIsMutating } from 'react-query';

const LIST_REFRESH_INTERVAL = 5000;

const createDatasetMutationKey = ['createDataset'];

export const useCreateDatasetMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    createDatasetMutationKey,
    async (variables: CreateDatasetOperationRequest) => {
      const api = await initializeAPI<DatasetApi>(DatasetApi);
      return api.createDataset(variables);
    },
  );
};

export const useValidateDatasetNameMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    ['validateDatasetName'],
    async (request: ValidateDatasetNameOperationRequest) => {
      const api = await initializeAPI<DatasetApi>(DatasetApi);
      return api.validateDatasetName(request);
    },
  );
};

export const getDatasetsAsTableQueryKey = (input: GetDatasetsRequest = {}) => [
  'getDatasetsAsTable',
  JSON.stringify(input),
];

export const useGetDatasetsAsTableQuery = (input: GetDatasetsRequest = {}) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    getDatasetsAsTableQueryKey(input),
    async ({ signal }) => {
      const api = await initializeAPI<DatasetApi>(DatasetApi);
      return api.getDatasetsAsTable(input, { signal });
    },
    { cacheTime: 0, refetchInterval: LIST_REFRESH_INTERVAL },
  );
};

export const getDatasetQueryKey = (datasetId: string | undefined) => [
  'getDataset',
  datasetId,
];

export const useGetDatasetById = (datasetId: string | undefined) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    getDatasetQueryKey(datasetId),
    async ({ signal }) => {
      const api = await initializeAPI<DatasetApi>(DatasetApi);
      return api.getDataset({ datasetId: datasetId || '' }, { signal });
    },
    { cacheTime: 0, enabled: Boolean(datasetId) },
  );
};

export const useAddAssetsToDatasetMutation = () => {
  const { initializeAPI } = useAPIContext();

  return useMutation(
    ['addAssetsToDataset'],
    async (variables: AddAssetsToDatasetOperationRequest) => {
      const api = await initializeAPI<DatasetApi>(DatasetApi);
      return api.addAssetsToDataset(variables);
    },
  );
};

const updateDatasetMutationKey = ['updateDataset'];

export const useUpdateDatasetMutation = () => {
  const { initializeAPI } = useAPIContext();

  return useMutation(
    updateDatasetMutationKey,
    async (variables: UpdateDatasetOperationRequest) => {
      const api = await initializeAPI<DatasetApi>(DatasetApi);
      return api.updateDataset(variables);
    },
  );
};

export const useDeleteDatasetMutation = () => {
  const { initializeAPI } = useAPIContext();

  return useMutation(
    ['deleteDataset'],
    async (variables: DeleteDatasetRequest) => {
      const api = await initializeAPI<DatasetApi>(DatasetApi);
      return api.deleteDataset(variables);
    },
  );
};

export const useCreateDynamicTagTableMutation = (datasetId: string) => {
  const { initializeAPI } = useAPIContext();

  return useMutation(
    ['createDynamicTagTable', datasetId],
    async (variables: CreateDynamicTagTableOperationRequest) => {
      const api = await initializeAPI<DatasetApi>(DatasetApi);
      return api.createDynamicTagTable(variables);
    },
  );
};

export const invalidateDatasetQueries = (
  queryClient: QueryClient,
  id?: string,
) =>
  Promise.all([
    queryClient.invalidateQueries(getDatasetsAsTableQueryKey()),
    ...(id ? [queryClient.invalidateQueries(getDatasetQueryKey(id))] : []),
  ]);

export function useIsDatasetMutatingOrFetching(id?: string): boolean {
  const creating = useIsMutating(createDatasetMutationKey);
  const updating = useIsMutating(updateDatasetMutationKey);
  const fetching = useIsFetching(getDatasetQueryKey(id));
  return creating > 0 || updating > 0 || fetching > 0;
}
