/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Pagination metadata
 * @export
 * @interface PagedPageDetailsResponse
 */
export interface PagedPageDetailsResponse {
    /**
     * Current page number
     * @type {number}
     * @memberof PagedPageDetailsResponse
     */
    currentPage: number;
    /**
     * Max page number based on limit and total number of items
     * @type {number}
     * @memberof PagedPageDetailsResponse
     */
    lastPage?: number | null;
    /**
     * Number of items to return
     * @type {number}
     * @memberof PagedPageDetailsResponse
     */
    limit: number;
    /**
     * Starting index to return
     * @type {number}
     * @memberof PagedPageDetailsResponse
     */
    offset: number;
    /**
     * Total number of items
     * @type {number}
     * @memberof PagedPageDetailsResponse
     */
    total?: number | null;
}

export function PagedPageDetailsResponseFromJSON(json: any): PagedPageDetailsResponse {
    return PagedPageDetailsResponseFromJSONTyped(json, false);
}

export function PagedPageDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedPageDetailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentPage': json['currentPage'],
        'lastPage': !exists(json, 'lastPage') ? undefined : json['lastPage'],
        'limit': json['limit'],
        'offset': json['offset'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function PagedPageDetailsResponseToJSON(value?: PagedPageDetailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentPage': value.currentPage,
        'lastPage': value.lastPage,
        'limit': value.limit,
        'offset': value.offset,
        'total': value.total,
    };
}

