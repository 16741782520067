import Dropdown from 'components/Dropdown';
import { useGetEmbeddingsQuery } from 'queries/embeddings';
import React, { useEffect, useRef } from 'react';
import { findDefaultEmbedding, findReducedEmbedding } from 'utils/embeddings';

interface BaseProps {
  selectedId: string | null;
  setSelectedId: (selectedId: string | null) => void;
}

interface InternalProps extends BaseProps {
  datasetId: string;
  disabled: boolean;
}

const EmbeddingDropdownInternal = function EmbeddingDropdownInternal({
  datasetId,
  selectedId,
  setSelectedId,
  disabled,
}: InternalProps) {
  const { data: embeddings, isLoading } = useGetEmbeddingsQuery(datasetId);
  const firstMount = useRef(true);

  useEffect(() => {
    if (!selectedId && embeddings?.data.length) {
      const reducedEmbedding = findReducedEmbedding(embeddings.data);
      const defaultEmbedding = findDefaultEmbedding(embeddings.data);
      if (reducedEmbedding) {
        setSelectedId(reducedEmbedding.embeddingId);
      } else if (defaultEmbedding) {
        setSelectedId(defaultEmbedding.embeddingId);
      } else {
        setSelectedId(embeddings.data[0]?.embeddingId);
      }
    }
  }, [embeddings, selectedId]);

  useEffect(() => {
    // Prevent this from running on mount because it causes no option to be selected when embeddings
    // are fetched from the react-query cache
    if (selectedId && !firstMount.current) {
      setSelectedId(null);
    }
    firstMount.current = false;
  }, [datasetId]);

  const options =
    embeddings?.data.map((embedding) => ({
      label: embedding.name,
      value: embedding.embeddingId,
    })) ?? [];

  return (
    <Dropdown
      disabled={disabled || options.length < 2}
      label="Embedding"
      onChange={setSelectedId}
      options={options}
      optionsLoading={isLoading}
      selected={selectedId || ''}
    />
  );
};

interface Props extends BaseProps {
  datasetId: string | null;
  disabled?: boolean;
}

const EmbeddingDropdown = function EmbeddingDropdown({
  datasetId,
  selectedId,
  setSelectedId,
  disabled,
}: Props) {
  if (datasetId) {
    return (
      <EmbeddingDropdownInternal
        datasetId={datasetId}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        disabled={Boolean(disabled)}
      />
    );
  }
  return <Dropdown label="Embedding" disabled options={[]} />;
};

EmbeddingDropdown.defaultProps = {
  disabled: false,
};

export default EmbeddingDropdown;
