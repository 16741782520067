import {
  Column,
  ConceptTableRow,
  ConceptsTableResponse,
  DatasetListResponse,
} from 'api/generated';
import classNames from 'classnames';
import EmptyStateMessage from 'components/EmptyStateMessage';
import FadeTransition from 'components/FadeTransition';
import LoadingTable from 'components/LoadingTable';
import Table from 'components/Table';
import DatasetFilter from 'pages/concepts/list/components/DatasetFilter';
import React, { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { PaginationState } from 'types/pagination';
import { TablePageSize } from 'types/table';

interface TableViewProps {
  concepts: ConceptsTableResponse | undefined;
  datasets: DatasetListResponse | undefined;
  datasetFilter?: string[];
  setDatasetFilter?: (datasetIds: string[]) => void;
  loading?: boolean;
  pagination: PaginationState;
  setPageSize: (size: TablePageSize) => void;
  loadPage: (page: number) => void;
  total: number | undefined;
}

const TableView: React.FunctionComponent<TableViewProps> = function TableView({
  concepts,
  datasets,
  loading,
  datasetFilter,
  setDatasetFilter,
  pagination,
  setPageSize,
  loadPage,
  total,
}) {
  const loadingTable = useMemo(() => <LoadingTable />, []);

  const columnsRef = useRef<Column[]>();
  if (concepts?.columns) {
    columnsRef.current = concepts?.columns;
  }

  const conceptsRef = useRef<ConceptTableRow[]>();
  if (concepts?.columns) {
    conceptsRef.current = concepts?.data;
  }

  const table = useMemo(
    () => (
      <Table
        columns={columnsRef.current ?? []}
        filters={{
          'dataset.name': (
            <DatasetFilter
              datasets={datasets?.data}
              selected={datasetFilter || []}
              setSelected={setDatasetFilter}
            />
          ),
        }}
        data={conceptsRef.current ?? []}
        idKey="conceptId"
        total={total ?? 0}
        activePage={pagination.page}
        pageSize={pagination.size}
        setPageSize={setPageSize}
        loadPage={loadPage}
        emptyMessage={
          <div className="text-center py-12 px-2 text-sm">
            <EmptyStateMessage>
              <p className="pb-4">No concepts to show</p>
            </EmptyStateMessage>
            <Link
              to="/concepts/create"
              className="text-blue-500 hover:text-blue-600 font-medium"
            >
              Create a Concept
            </Link>
          </div>
        }
        loading={!concepts?.data}
      />
    ),
    [concepts?.data, datasets?.data],
  );

  return (
    <>
      <FadeTransition
        show={Boolean(!loading && concepts)}
        appear
        unmount={false}
      >
        <div className={classNames({ hidden: !columnsRef.current })}>
          {table}
        </div>
      </FadeTransition>
      <FadeTransition
        show={Boolean(loading) && !columnsRef.current}
        appear
        enterDelayMs={500}
        exit={false}
      >
        {!!loading && loadingTable}
      </FadeTransition>
    </>
  );
};

TableView.defaultProps = {
  loading: false,
  datasetFilter: undefined,
  setDatasetFilter: undefined,
};

export default TableView;
