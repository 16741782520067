import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import colors from 'tailwindcss/colors';
import ChartWrapper from 'components/ChartWrapper';
import IconButton from 'components/IconButton';
import { ClipLoader } from 'react-spinners';
import ConceptPredictionChart from './ConceptPredictionChart';

type ChartData = {
  name: string;
  value: number;
  label: string;
};

interface PredictionsEstimatesProps {
  assetCount: number;
  chartData?: ChartData[];
  displayChartLoader: boolean;
  isRefetchingPredictions: boolean;
}

const PredictionEstimates: React.FC<PredictionsEstimatesProps> = ({
  chartData,
  assetCount,
  displayChartLoader,
  isRefetchingPredictions,
}) => (
  <ChartWrapper
    title="Classification estimates"
    isLoading={isRefetchingPredictions}
    subtitle={
      isRefetchingPredictions ? (
        'Updating estimates...'
      ) : (
        <div>
          These are estimates for the number of assets in the dataset matching
          this concept. As you label more, the model understands the concept
          better, so certainty will increase and uncertainty will decrease.
          Note: values displayed on a log scale
          <span className="ml-1 inline-block align-text-bottom">
            <IconButton
              fill="text-slate-400"
              Icon={InformationCircleIcon}
              onClick={() => null}
              size="medium"
              tooltip="The estimates are displayed on a logarithmic scale counting up in powers of 10 (10, 100, 1k, 10k, etc.)"
            />
          </span>
        </div>
      )
    }
  >
    {displayChartLoader ? (
      <div className="h-[20rem] w-full flex items-center justify-center">
        <ClipLoader
          color={colors.emerald['500']}
          loading
          size={25}
          speedMultiplier={0.75}
        />
      </div>
    ) : (
      <ConceptPredictionChart assetCount={assetCount} chartData={chartData} />
    )}
  </ChartWrapper>
);
PredictionEstimates.defaultProps = {
  chartData: undefined,
};

export default PredictionEstimates;
