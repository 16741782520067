/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Column,
    ColumnFromJSON,
    ColumnFromJSONTyped,
    ColumnToJSON,
} from './Column';
import {
    DatasetTableRow,
    DatasetTableRowFromJSON,
    DatasetTableRowFromJSONTyped,
    DatasetTableRowToJSON,
} from './DatasetTableRow';
import {
    PagedMetaResponse,
    PagedMetaResponseFromJSON,
    PagedMetaResponseFromJSONTyped,
    PagedMetaResponseToJSON,
} from './PagedMetaResponse';

/**
 * A response containing data to be represented in a table
 * @export
 * @interface DatasetTableResponse
 */
export interface DatasetTableResponse {
    /**
     * 
     * @type {PagedMetaResponse}
     * @memberof DatasetTableResponse
     */
    meta: PagedMetaResponse;
    /**
     * The table rows
     * @type {Array<DatasetTableRow>}
     * @memberof DatasetTableResponse
     */
    data: Array<DatasetTableRow>;
    /**
     * The table columns
     * @type {Array<Column>}
     * @memberof DatasetTableResponse
     */
    columns: Array<Column>;
}

export function DatasetTableResponseFromJSON(json: any): DatasetTableResponse {
    return DatasetTableResponseFromJSONTyped(json, false);
}

export function DatasetTableResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetTableResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': PagedMetaResponseFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(DatasetTableRowFromJSON)),
        'columns': ((json['columns'] as Array<any>).map(ColumnFromJSON)),
    };
}

export function DatasetTableResponseToJSON(value?: DatasetTableResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': PagedMetaResponseToJSON(value.meta),
        'data': ((value.data as Array<any>).map(DatasetTableRowToJSON)),
        'columns': ((value.columns as Array<any>).map(ColumnToJSON)),
    };
}

