/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssetDownloadResponse,
  AssetType,
  ErrorResponse,
  GenerateTrailerRequest,
  HTTPValidationError,
  PagedDataResponseAssetResponse,
  SearchFiltersResponse,
  SearchType,
  SimilaritySearchResponse,
} from '../models';
import {
    AssetDownloadResponseFromJSON,
    AssetDownloadResponseToJSON,
    AssetTypeFromJSON,
    AssetTypeToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GenerateTrailerRequestFromJSON,
    GenerateTrailerRequestToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    PagedDataResponseAssetResponseFromJSON,
    PagedDataResponseAssetResponseToJSON,
    SearchFiltersResponseFromJSON,
    SearchFiltersResponseToJSON,
    SearchTypeFromJSON,
    SearchTypeToJSON,
    SimilaritySearchResponseFromJSON,
    SimilaritySearchResponseToJSON,
} from '../models';

export interface GenerateTrailerOperationRequest {
    videoId: string;
    generateTrailerRequest: GenerateTrailerRequest;
}

export interface GetImagesByTextRequest {
    datasetId: string;
    query: string | null;
    conceptIds?: Array<string> | null;
    limit?: number | null;
    assetType?: AssetType;
    searchType?: SearchType;
    metadataFilters?: string;
}

export interface GetImagesByTextGroupedRequest {
    datasetId: string;
    query: string;
    groupBy?: string | null;
    limit?: number | null;
    assetType?: AssetType;
}

export interface GetSearchFiltersRequest {
    datasetId: string;
    offset?: number | null;
    limit?: number | null;
}

export interface GetSimilarImagesRequest {
    datasetId: string;
    coactiveImageId: string | null;
    offset?: number | null;
    limit?: number | null;
}

export interface GetSimilarImagesGroupedSemanticUiRequest {
    embeddingId: string;
    groupBy: string | null;
    file: Blob;
    metadataKey?: string;
    metadataValue?: string;
    limit?: number | null;
}

export interface GetSimilarImagesGroupedUiRequest {
    embeddingId: string;
    file: Blob;
    groupBy?: string | null;
    limit?: number | null;
}

export interface GetVideoThumbnailsRequest {
    videoId: string;
    query: string;
    conceptVersionIds?: Array<string> | null;
    conceptCoefficients?: Array<number> | null;
    limit?: number | null;
}

/**
 * SimilaritySearchApi - interface
 * 
 * @export
 * @interface SimilaritySearchApiInterface
 */
export interface SimilaritySearchApiInterface {
    /**
     * Generate trailer for video from selected shots in video
     * @summary Generate trailer for video
     * @param {string} videoId The unique identifier for the query
     * @param {GenerateTrailerRequest} generateTrailerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimilaritySearchApiInterface
     */
    generateTrailerRaw(requestParameters: GenerateTrailerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetDownloadResponse>>;

    /**
     * Generate trailer for video from selected shots in video
     * Generate trailer for video
     */
    generateTrailer(requestParameters: GenerateTrailerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetDownloadResponse>;

    /**
     * Returns the images most relevant to the text query provided within the same embedding space
     * @summary Get images by the semantic notion of the text query
     * @param {string} datasetId The unique identifier for the dataset
     * @param {string} query The query string to fetch images of
     * @param {Array<string>} [conceptIds] The ids of the concepts to include in the natural_language_query
     * @param {number} [limit] Max number of items to return
     * @param {AssetType} [assetType] Asset type to filter by
     * @param {SearchType} [searchType] Search type to use
     * @param {string} [metadataFilters] List of metadata filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimilaritySearchApiInterface
     */
    getImagesByTextRaw(requestParameters: GetImagesByTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilaritySearchResponse>>;

    /**
     * Returns the images most relevant to the text query provided within the same embedding space
     * Get images by the semantic notion of the text query
     */
    getImagesByText(requestParameters: GetImagesByTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilaritySearchResponse>;

    /**
     * Returns the images most relevant to the text query provided within the same embedding space grouped by the group by key
     * @summary Get images grouped by the \"group by\" key and semantic notion of the text query
     * @param {string} datasetId The unique identifier for the dataset
     * @param {string} query The query string to fetch images of
     * @param {string} [groupBy] If provided the result will be grouped by the provided asset metadata key
     * @param {number} [limit] Max number of items to return
     * @param {AssetType} [assetType] Asset type to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimilaritySearchApiInterface
     */
    getImagesByTextGroupedRaw(requestParameters: GetImagesByTextGroupedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilaritySearchResponse>>;

    /**
     * Returns the images most relevant to the text query provided within the same embedding space grouped by the group by key
     * Get images grouped by the \"group by\" key and semantic notion of the text query
     */
    getImagesByTextGrouped(requestParameters: GetImagesByTextGroupedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilaritySearchResponse>;

    /**
     * Gets available filter columns
     * @summary Get available filter columns
     * @param {string} datasetId The unique identifier for the dataset
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimilaritySearchApiInterface
     */
    getSearchFiltersRaw(requestParameters: GetSearchFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchFiltersResponse>>;

    /**
     * Gets available filter columns
     * Get available filter columns
     */
    getSearchFilters(requestParameters: GetSearchFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchFiltersResponse>;

    /**
     * Returns the images most similar to the image at the provided path within the same embedding space
     * @summary Get similar images
     * @param {string} datasetId The unique identifier for the dataset
     * @param {string} coactiveImageId The coactive image id for which to perform similarity search
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimilaritySearchApiInterface
     */
    getSimilarImagesRaw(requestParameters: GetSimilarImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedDataResponseAssetResponse>>;

    /**
     * Returns the images most similar to the image at the provided path within the same embedding space
     * Get similar images
     */
    getSimilarImages(requestParameters: GetSimilarImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedDataResponseAssetResponse>;

    /**
     * Returns the images most similar to the image at the provided path within the same embedding space grouped by the group by key and optionally by the semantic notion of metadata about the assets
     * @summary Get similar images grouped by the group by key and semantic notion of metadata
     * @param {string} embeddingId The unique identifier for the embedding
     * @param {string} groupBy If provided the result will be grouped by the provided asset metadata key
     * @param {Blob} file An image on which to perform similarity search
     * @param {string} [metadataKey] The metadata key field to add the semantic search on, if left blank all columns will be taken into consideration. Required if metadata key is passed in, else optional.
     * @param {string} [metadataValue] The metadata value to use for the semantic search. Required if metadata key is passed in, else optional.
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimilaritySearchApiInterface
     */
    getSimilarImagesGroupedSemanticUiRaw(requestParameters: GetSimilarImagesGroupedSemanticUiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilaritySearchResponse>>;

    /**
     * Returns the images most similar to the image at the provided path within the same embedding space grouped by the group by key and optionally by the semantic notion of metadata about the assets
     * Get similar images grouped by the group by key and semantic notion of metadata
     */
    getSimilarImagesGroupedSemanticUi(requestParameters: GetSimilarImagesGroupedSemanticUiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilaritySearchResponse>;

    /**
     * Returns the images most similar to the image at the provided path within the same embedding space grouped by the group by key
     * @summary Get similar images grouped by the group by key
     * @param {string} embeddingId The unique identifier for the embedding
     * @param {Blob} file An image on which to perform similarity search
     * @param {string} [groupBy] If provided the result will be grouped by the provided asset metadata key
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimilaritySearchApiInterface
     */
    getSimilarImagesGroupedUiRaw(requestParameters: GetSimilarImagesGroupedUiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilaritySearchResponse>>;

    /**
     * Returns the images most similar to the image at the provided path within the same embedding space grouped by the group by key
     * Get similar images grouped by the group by key
     */
    getSimilarImagesGroupedUi(requestParameters: GetSimilarImagesGroupedUiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilaritySearchResponse>;

    /**
     * Returns the images most relevant to the text query while also being relevantto the concepts, weighted by their coefficient.
     * @summary Returns the images that both match the text query and provided concepts.
     * @param {string} videoId The video_id of the video to perform search on.
     * @param {string} query The query string to fetch images of
     * @param {Array<string>} [conceptVersionIds] The version ids of the concepts to use for search
     * @param {Array<number>} [conceptCoefficients] The coefficients to weigh each concept during search.
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimilaritySearchApiInterface
     */
    getVideoThumbnailsRaw(requestParameters: GetVideoThumbnailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilaritySearchResponse>>;

    /**
     * Returns the images most relevant to the text query while also being relevantto the concepts, weighted by their coefficient.
     * Returns the images that both match the text query and provided concepts.
     */
    getVideoThumbnails(requestParameters: GetVideoThumbnailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilaritySearchResponse>;

}

/**
 * 
 */
export class SimilaritySearchApi extends runtime.BaseAPI implements SimilaritySearchApiInterface {

    /**
     * Generate trailer for video from selected shots in video
     * Generate trailer for video
     */
    async generateTrailerRaw(requestParameters: GenerateTrailerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetDownloadResponse>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling generateTrailer.');
        }

        if (requestParameters.generateTrailerRequest === null || requestParameters.generateTrailerRequest === undefined) {
            throw new runtime.RequiredError('generateTrailerRequest','Required parameter requestParameters.generateTrailerRequest was null or undefined when calling generateTrailer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/similarity-search/videos/{video_id}/trailer`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateTrailerRequestToJSON(requestParameters.generateTrailerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetDownloadResponseFromJSON(jsonValue));
    }

    /**
     * Generate trailer for video from selected shots in video
     * Generate trailer for video
     */
    async generateTrailer(requestParameters: GenerateTrailerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetDownloadResponse> {
        const response = await this.generateTrailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the images most relevant to the text query provided within the same embedding space
     * Get images by the semantic notion of the text query
     */
    async getImagesByTextRaw(requestParameters: GetImagesByTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilaritySearchResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling getImagesByText.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling getImagesByText.');
        }

        const queryParameters: any = {};

        if (requestParameters.conceptIds) {
            queryParameters['concept_ids'] = requestParameters.conceptIds;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.assetType !== undefined) {
            queryParameters['asset_type'] = requestParameters.assetType;
        }

        if (requestParameters.searchType !== undefined) {
            queryParameters['search_type'] = requestParameters.searchType;
        }

        if (requestParameters.metadataFilters !== undefined) {
            queryParameters['metadata_filters'] = requestParameters.metadataFilters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/similarity-search/similarity-search/datasets/{dataset_id}/text`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimilaritySearchResponseFromJSON(jsonValue));
    }

    /**
     * Returns the images most relevant to the text query provided within the same embedding space
     * Get images by the semantic notion of the text query
     */
    async getImagesByText(requestParameters: GetImagesByTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilaritySearchResponse> {
        const response = await this.getImagesByTextRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the images most relevant to the text query provided within the same embedding space grouped by the group by key
     * Get images grouped by the \"group by\" key and semantic notion of the text query
     */
    async getImagesByTextGroupedRaw(requestParameters: GetImagesByTextGroupedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilaritySearchResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling getImagesByTextGrouped.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling getImagesByTextGrouped.');
        }

        const queryParameters: any = {};

        if (requestParameters.groupBy !== undefined) {
            queryParameters['group_by'] = requestParameters.groupBy;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.assetType !== undefined) {
            queryParameters['asset_type'] = requestParameters.assetType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/similarity-search/similarity-search/datasets/{dataset_id}/text/grouped`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimilaritySearchResponseFromJSON(jsonValue));
    }

    /**
     * Returns the images most relevant to the text query provided within the same embedding space grouped by the group by key
     * Get images grouped by the \"group by\" key and semantic notion of the text query
     */
    async getImagesByTextGrouped(requestParameters: GetImagesByTextGroupedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilaritySearchResponse> {
        const response = await this.getImagesByTextGroupedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets available filter columns
     * Get available filter columns
     */
    async getSearchFiltersRaw(requestParameters: GetSearchFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchFiltersResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling getSearchFilters.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/similarity-search/datasets/{dataset_id}/filters`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchFiltersResponseFromJSON(jsonValue));
    }

    /**
     * Gets available filter columns
     * Get available filter columns
     */
    async getSearchFilters(requestParameters: GetSearchFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchFiltersResponse> {
        const response = await this.getSearchFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the images most similar to the image at the provided path within the same embedding space
     * Get similar images
     */
    async getSimilarImagesRaw(requestParameters: GetSimilarImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedDataResponseAssetResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling getSimilarImages.');
        }

        if (requestParameters.coactiveImageId === null || requestParameters.coactiveImageId === undefined) {
            throw new runtime.RequiredError('coactiveImageId','Required parameter requestParameters.coactiveImageId was null or undefined when calling getSimilarImages.');
        }

        const queryParameters: any = {};

        if (requestParameters.coactiveImageId !== undefined) {
            queryParameters['coactive_image_id'] = requestParameters.coactiveImageId;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/similarity-search/datasets/{dataset_id}/similar-images`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedDataResponseAssetResponseFromJSON(jsonValue));
    }

    /**
     * Returns the images most similar to the image at the provided path within the same embedding space
     * Get similar images
     */
    async getSimilarImages(requestParameters: GetSimilarImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedDataResponseAssetResponse> {
        const response = await this.getSimilarImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the images most similar to the image at the provided path within the same embedding space grouped by the group by key and optionally by the semantic notion of metadata about the assets
     * Get similar images grouped by the group by key and semantic notion of metadata
     */
    async getSimilarImagesGroupedSemanticUiRaw(requestParameters: GetSimilarImagesGroupedSemanticUiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilaritySearchResponse>> {
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId','Required parameter requestParameters.embeddingId was null or undefined when calling getSimilarImagesGroupedSemanticUi.');
        }

        if (requestParameters.groupBy === null || requestParameters.groupBy === undefined) {
            throw new runtime.RequiredError('groupBy','Required parameter requestParameters.groupBy was null or undefined when calling getSimilarImagesGroupedSemanticUi.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling getSimilarImagesGroupedSemanticUi.');
        }

        const queryParameters: any = {};

        if (requestParameters.groupBy !== undefined) {
            queryParameters['group_by'] = requestParameters.groupBy;
        }

        if (requestParameters.metadataKey !== undefined) {
            queryParameters['metadata_key'] = requestParameters.metadataKey;
        }

        if (requestParameters.metadataValue !== undefined) {
            queryParameters['metadata_value'] = requestParameters.metadataValue;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/ui/similarity-search/embeddings/{embedding_id}/grouped-search/semantic`.replace(`{${"embedding_id"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimilaritySearchResponseFromJSON(jsonValue));
    }

    /**
     * Returns the images most similar to the image at the provided path within the same embedding space grouped by the group by key and optionally by the semantic notion of metadata about the assets
     * Get similar images grouped by the group by key and semantic notion of metadata
     */
    async getSimilarImagesGroupedSemanticUi(requestParameters: GetSimilarImagesGroupedSemanticUiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilaritySearchResponse> {
        const response = await this.getSimilarImagesGroupedSemanticUiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the images most similar to the image at the provided path within the same embedding space grouped by the group by key
     * Get similar images grouped by the group by key
     */
    async getSimilarImagesGroupedUiRaw(requestParameters: GetSimilarImagesGroupedUiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilaritySearchResponse>> {
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId','Required parameter requestParameters.embeddingId was null or undefined when calling getSimilarImagesGroupedUi.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling getSimilarImagesGroupedUi.');
        }

        const queryParameters: any = {};

        if (requestParameters.groupBy !== undefined) {
            queryParameters['group_by'] = requestParameters.groupBy;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/ui/similarity-search/embeddings/{embedding_id}/grouped-search`.replace(`{${"embedding_id"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimilaritySearchResponseFromJSON(jsonValue));
    }

    /**
     * Returns the images most similar to the image at the provided path within the same embedding space grouped by the group by key
     * Get similar images grouped by the group by key
     */
    async getSimilarImagesGroupedUi(requestParameters: GetSimilarImagesGroupedUiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilaritySearchResponse> {
        const response = await this.getSimilarImagesGroupedUiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the images most relevant to the text query while also being relevantto the concepts, weighted by their coefficient.
     * Returns the images that both match the text query and provided concepts.
     */
    async getVideoThumbnailsRaw(requestParameters: GetVideoThumbnailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilaritySearchResponse>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling getVideoThumbnails.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling getVideoThumbnails.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.conceptVersionIds) {
            queryParameters['concept_version_ids'] = requestParameters.conceptVersionIds;
        }

        if (requestParameters.conceptCoefficients) {
            queryParameters['concept_coefficients'] = requestParameters.conceptCoefficients;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/similarity-search/videos/{video_id}/hybrid_search`.replace(`{${"video_id"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimilaritySearchResponseFromJSON(jsonValue));
    }

    /**
     * Returns the images most relevant to the text query while also being relevantto the concepts, weighted by their coefficient.
     * Returns the images that both match the text query and provided concepts.
     */
    async getVideoThumbnails(requestParameters: GetVideoThumbnailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilaritySearchResponse> {
        const response = await this.getVideoThumbnailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
