/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PagedMetaResponse,
    PagedMetaResponseFromJSON,
    PagedMetaResponseFromJSONTyped,
    PagedMetaResponseToJSON,
} from './PagedMetaResponse';
import {
    QueryResponseUI,
    QueryResponseUIFromJSON,
    QueryResponseUIFromJSONTyped,
    QueryResponseUIToJSON,
} from './QueryResponseUI';

/**
 * A response containing paged data
 * @export
 * @interface QueryListResponseUI
 */
export interface QueryListResponseUI {
    /**
     * 
     * @type {PagedMetaResponse}
     * @memberof QueryListResponseUI
     */
    meta: PagedMetaResponse;
    /**
     * The paginated data
     * @type {Array<QueryResponseUI>}
     * @memberof QueryListResponseUI
     */
    data: Array<QueryResponseUI>;
}

export function QueryListResponseUIFromJSON(json: any): QueryListResponseUI {
    return QueryListResponseUIFromJSONTyped(json, false);
}

export function QueryListResponseUIFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryListResponseUI {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': PagedMetaResponseFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(QueryResponseUIFromJSON)),
    };
}

export function QueryListResponseUIToJSON(value?: QueryListResponseUI | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': PagedMetaResponseToJSON(value.meta),
        'data': ((value.data as Array<any>).map(QueryResponseUIToJSON)),
    };
}

