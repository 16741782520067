/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Column,
    ColumnFromJSON,
    ColumnFromJSONTyped,
    ColumnToJSON,
} from './Column';
import {
    ConceptTableRow,
    ConceptTableRowFromJSON,
    ConceptTableRowFromJSONTyped,
    ConceptTableRowToJSON,
} from './ConceptTableRow';
import {
    PagedMetaResponse,
    PagedMetaResponseFromJSON,
    PagedMetaResponseFromJSONTyped,
    PagedMetaResponseToJSON,
} from './PagedMetaResponse';

/**
 * A response containing data to be represented in a table
 * @export
 * @interface ConceptsTableResponse
 */
export interface ConceptsTableResponse {
    /**
     * 
     * @type {PagedMetaResponse}
     * @memberof ConceptsTableResponse
     */
    meta: PagedMetaResponse;
    /**
     * The table rows
     * @type {Array<ConceptTableRow>}
     * @memberof ConceptsTableResponse
     */
    data: Array<ConceptTableRow>;
    /**
     * The table columns
     * @type {Array<Column>}
     * @memberof ConceptsTableResponse
     */
    columns: Array<Column>;
}

export function ConceptsTableResponseFromJSON(json: any): ConceptsTableResponse {
    return ConceptsTableResponseFromJSONTyped(json, false);
}

export function ConceptsTableResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConceptsTableResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': PagedMetaResponseFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(ConceptTableRowFromJSON)),
        'columns': ((json['columns'] as Array<any>).map(ColumnFromJSON)),
    };
}

export function ConceptsTableResponseToJSON(value?: ConceptsTableResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': PagedMetaResponseToJSON(value.meta),
        'data': ((value.data as Array<any>).map(ConceptTableRowToJSON)),
        'columns': ((value.columns as Array<any>).map(ColumnToJSON)),
    };
}

