import DropdownButton from 'components/DropdownButton';
import ListViewToggle, { ListView } from 'components/ListViewToggle';
import Main from 'components/Main';
import PageHeader from 'components/PageHeader';
import { toggles } from 'config';
import {
  useDeleteAllConceptsMutation,
  useGetConcepts,
} from 'pages/concepts/list/queries';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetDatasetsQuery } from 'pages/concepts/queries';
import { PaginationState } from 'types/pagination';
import { TablePageSize } from 'types/table';
import usePagination from 'hooks/usePagination';
import { getConceptsQueryKey } from 'queries/concepts';
import { useQueryClient } from 'react-query';
import TableView from './components/TableView';
import TileView from './components/TileView';

const ConceptsList = function ConceptsList() {
  const pagination = usePagination();
  const paginationState = useMemo<PaginationState>(
    () => ({ page: pagination.page, size: pagination.size }),
    [pagination.page, pagination.size],
  );
  const navigate = useNavigate();
  const [datasetFilter, setDatasetFilter] = useState<string[] | undefined>(
    undefined,
  );
  const { mutate: deleteAllConcepts, isLoading: isDeletingConcepts } =
    useDeleteAllConceptsMutation();
  const [activeView, setActiveView] = useState<ListView>('table');
  const queryClient = useQueryClient();

  const { data: datasets } = useGetDatasetsQuery();
  const {
    data: concepts,
    isLoading,
    refetch,
  } = useGetConcepts(
    datasetFilter?.length === 0 ? undefined : datasetFilter,
    (pagination.page - 1) * pagination.size,
    pagination.size,
  );

  useEffect(() => {
    if (concepts) {
      if (!concepts.data.length && (concepts.meta?.page?.total || 0) > 0) {
        pagination.setPage(1);
      }
    }
  }, [concepts]);

  const doDeleteAllConcepts = () => {
    deleteAllConcepts(undefined, {
      onSuccess: () => {
        queryClient.invalidateQueries(getConceptsQueryKey());
        toast.success(<span>All concepts successfully deleted</span>);
        refetch();
      },
      onError: () => {
        toast.error(<span>An error occurred while deleting all concepts</span>);
        refetch();
      },
    });
  };

  const setPageSize = (newSize: TablePageSize) => {
    pagination.setSize(newSize);
  };

  const loadPage = (page: number) => {
    pagination.setPage(page);
  };

  const setDatasetFilters = (datasetIds?: string[]) => {
    setDatasetFilter(datasetIds);
  };

  return (
    <Main>
      <div className="max-w-8xl mx-auto">
        <PageHeader
          title={<h1>Concepts</h1>}
          backButtonTo=""
          actions={
            <DropdownButton
              onClick={() => navigate('/concepts/create')}
              actions={[
                {
                  key: 'csv',
                  label: 'Bulk Create From CSV',
                  action: () => navigate('upload'),
                },
                {
                  key: 'delete',
                  label: 'Delete All',
                  action: doDeleteAllConcepts,
                  disabled: isDeletingConcepts,
                  danger: true,
                },
              ]}
            >
              Create a Concept
            </DropdownButton>
          }
        />
        {toggles.conceptsGridView() && (
          <div className="flex justify-end pt-4">
            <ListViewToggle
              onToggle={setActiveView}
              activeView={activeView}
              views={['table', 'tile']}
            />
          </div>
        )}
        {toggles.conceptsGridView() && activeView === 'table' ? (
          <TableView
            concepts={concepts}
            loading={isLoading}
            datasets={datasets}
            datasetFilter={datasetFilter}
            setDatasetFilter={setDatasetFilters}
            pagination={paginationState}
            setPageSize={setPageSize}
            loadPage={loadPage}
            total={concepts?.meta?.page?.total ?? undefined}
          />
        ) : undefined}
        {toggles.conceptsGridView() && activeView === 'tile' ? (
          <TileView concepts={concepts} />
        ) : undefined}
        {!toggles.conceptsGridView() ? (
          <div className="pt-8">
            <TableView
              concepts={concepts}
              loading={isLoading}
              datasets={datasets}
              datasetFilter={datasetFilter}
              setDatasetFilter={setDatasetFilters}
              pagination={paginationState}
              setPageSize={setPageSize}
              loadPage={loadPage}
              total={concepts?.meta?.page?.total ?? undefined}
            />
          </div>
        ) : undefined}
      </div>
    </Main>
  );
};

export default ConceptsList;
