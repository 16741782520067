import { DatasetStatusEnum } from 'api/generated';
import Dropdown from 'components/Dropdown';
import { useGetDatasetsQuery } from 'pages/concepts/queries';
import React, { useEffect, useMemo } from 'react';

interface Props {
  selectedId: string | null;
  setSelectedId: (selectedId: string) => void;
  disabled?: boolean;
  hideLabel?: boolean;
  statusesToInclude?: DatasetStatusEnum[];
}

const DatasetDropdown = function DatasetDropdown({
  disabled,
  hideLabel,
  selectedId,
  setSelectedId,
  statusesToInclude,
}: Props) {
  const { data, isLoading } = useGetDatasetsQuery(statusesToInclude);
  const datasets = useMemo(() => data?.data || [], [data]);
  useEffect(() => {
    if (!selectedId && datasets.length === 1) {
      setSelectedId(datasets[0]?.datasetId ?? '');
    }
  }, [datasets]);
  return (
    <Dropdown
      className="min-w-[200px]"
      disabled={disabled || datasets.length === 0}
      label={hideLabel ? undefined : 'Dataset'}
      onChange={setSelectedId}
      options={datasets.map((dataset) => ({
        label: dataset?.name ?? '',
        value: dataset?.datasetId ?? '',
      }))}
      optionsLoading={isLoading}
      selected={selectedId || ''}
    />
  );
};

DatasetDropdown.defaultProps = {
  statusesToInclude: undefined,
  hideLabel: false,
  disabled: false,
};

export default DatasetDropdown;
