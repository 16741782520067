import React from 'react';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { DatasetStatusEnum, Permissions } from 'api/generated';
import AddAssetsViewContainer from 'pages/datasets/components/AddAssetsViewContainer';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useUserContext } from 'context/UserContext';

interface DatasetCreatedProps {
  datasetId: string;
  datasetName: string;
  datasetStatus: DatasetStatusEnum;
}

/**
 * A component to show a confirmation screen that a dataset was created successfully.
 * This component may also show a message that someone from Coactive will follow-up to
 * assist in adding their data from an unsupported data source.
 */
const DatasetCreated = function DatasetCreated({
  datasetId,
  datasetName,
  datasetStatus,
}: DatasetCreatedProps) {
  const navigate = useNavigate();
  const { me } = useUserContext();
  return (
    <AddAssetsViewContainer
      title={
        <>
          <strong>{datasetName}</strong> has been created!
        </>
      }
      icon={CheckIcon}
      isCreate
    >
      <p className="text-sm pt-4 pb-8">
        {datasetStatus === DatasetStatusEnum.Incomplete ? (
          <span>
            Someone from Coactive will follow up through email within 1-2
            business days to help you add your data so you can start searching
            through your dataset.
          </span>
        ) : (
          <span>
            You can start searching through your dataset and invite teammates.
          </span>
        )}
      </p>
      <Button
        onClick={() => navigate(`/datasets/${datasetId}`, { replace: true })}
      >
        Go to dataset
      </Button>
      <div className="inline ml-4">
        {datasetStatus === DatasetStatusEnum.Incomplete && (
          <Button
            onClick={() => window.open('https://coactive.ai/support')}
            buttonStyle="secondary"
          >
            Contact us
          </Button>
        )}
        {datasetStatus !== DatasetStatusEnum.Incomplete &&
          me?.permissions?.includes(Permissions.Createusers) && (
            <Button
              onClick={() => navigate('/settings/users')}
              buttonStyle="secondary"
            >
              Invite teammate to Coactive
            </Button>
          )}
      </div>
    </AddAssetsViewContainer>
  );
};

export default DatasetCreated;
