import { UsersIcon } from '@heroicons/react/24/outline';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { FieldValidation } from 'api/generated';
import classNames from 'classnames';
import ErrorText from 'components/ErrorText';
import useMounted from 'hooks/useMounted';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  ClipboardEvent,
  Component,
  ForwardedRef,
  PropsWithRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

const VALIDATION_DELAY_MS = 200;

interface TextInputProps {
  ref?: ForwardedRef<HTMLInputElement | undefined>;
  id: string;
  label?: string;
  name: string;
  description?: string;
  type?: string;
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  error?: string;
  setHasError?: (hasError: boolean) => void;
  icon?: Component;
  button?: {
    component: ReactElement<any, any>;
    onClick: (value: string | undefined) => void;
  };
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  // A function that returns a promise of an error message.
  validation?: (
    value: string,
  ) => Promise<{ valid: FieldValidation; unique: FieldValidation } | undefined>;
  executeValidationOnBlur?: boolean;
  textInputStyle?: 'default' | 'inline';
  prefix?: string;
  optional?: boolean;
  inputClassName?: string;
}

const TextInput: React.FC<PropsWithRef<TextInputProps>> = React.forwardRef(
  (
    props: PropsWithRef<TextInputProps>,
    forwardRef: React.ForwardedRef<HTMLInputElement | undefined>,
  ) => {
    const {
      id,
      name,
      type,
      defaultValue: defaultValueProp,
      value,
      placeholder,
      error,
      setHasError,
      label,
      description,
      button,
      icon,
      onChange: onChangeProp,
      onKeyUp: onKeyUpProp,
      disabled,
      validation,
      executeValidationOnBlur,
      textInputStyle,
      prefix,
      optional,
      inputClassName,
    } = props;
    const [unique, setUnique] = useState<FieldValidation | undefined>();
    const [valid, setValid] = useState<FieldValidation | undefined>();
    const { mounted } = useMounted();
    const internalRef = React.useRef<HTMLInputElement>();
    useImperativeHandle(forwardRef, () => internalRef.current);

    const changeRef = React.useRef<any>();

    useEffect(
      () => () => {
        clearTimeout(changeRef.current);
      },
      [],
    );

    const getFullInputValue = useCallback(
      (v?: string) => (prefix && v ? prefix + v : v),
      [prefix],
    );

    const onKeyUp = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (button?.onClick) {
          if (button && e.key === 'Enter') {
            button?.onClick(getFullInputValue(internalRef.current?.value));
          }
        }
      },
      [button],
    );

    const validate = useCallback(
      (v: string) => {
        if (validation) {
          changeRef.current = setTimeout(async () => {
            const response = await validation?.(v);
            if (mounted.current) {
              setUnique(response?.unique);
              setValid(response?.valid);
              setHasError?.(
                response?.unique?.value === false ||
                  response?.valid?.value === false,
              );
            }
          }, VALIDATION_DELAY_MS);
        }
      },
      [validation, executeValidationOnBlur, setUnique, setValid, setHasError],
    );

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        clearTimeout(changeRef.current);
        if (
          prefix &&
          e.target.value &&
          e.target.value.split(prefix)[0] === ''
        ) {
          e.target.value = e.target.value.replace(prefix, '');
        }
        const v = getFullInputValue(e.target.value) ?? '';

        if (!executeValidationOnBlur) {
          validate(v);
        }

        onChangeProp?.({
          ...e,
          target: { ...e.target, value: v },
        });
      },
      [executeValidationOnBlur, onChangeProp, prefix, validate],
    );

    const defaultValue = useMemo(() => {
      if (
        prefix &&
        defaultValueProp &&
        defaultValueProp.split(prefix)[0] === ''
      ) {
        return defaultValueProp.replace(prefix, '');
      }
      return defaultValueProp;
    }, [defaultValueProp]);

    const onBlur = useCallback(async () => {
      if (!executeValidationOnBlur) return;
      if (internalRef.current?.value) {
        const v = getFullInputValue(internalRef.current?.value);
        const response = await validation?.(v ?? '');
        if (mounted.current) {
          setUnique(response?.unique);
          setValid(response?.valid);
          setHasError?.(
            response?.unique?.value === false ||
              response?.valid?.value === false,
          );
        }
      } else {
        setUnique({ value: true });
        setValid({ value: true });
        setHasError?.(false);
      }
    }, [executeValidationOnBlur, validation, prefix]);

    const onPaste = useCallback(
      (e: ClipboardEvent<HTMLInputElement>) => {
        // TODO: Account for cursor position & text selection
        const clipboardData =
          e.clipboardData ||
          (e as any).originalEvent.clipboardData ||
          (window as any).clipboardData;
        if (clipboardData) {
          let text = clipboardData.getData('text');
          if (prefix) {
            text = text.replace(prefix, '');
          }
          (e.target as any).value = text;
          e.preventDefault();
          onChange({ target: { value: text } } as any);
        }
      },
      [prefix, onChange],
    );

    // If a default value is provided, validate it on mount.
    useEffect(() => {
      if (defaultValue) {
        if (prefix) {
          validate(prefix + defaultValue.replace(prefix, ''));
        } else {
          validate(defaultValue);
        }
      }
    }, []);

    const errorMessage = valid?.message || unique?.message || error;

    return (
      <div className={textInputStyle === 'inline' ? 'inline' : 'block'}>
        {Boolean(label) && (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        {Boolean(description) && <span>{description}</span>}
        <div
          className={classNames(
            'mt-1 rounded-md shadow-sm relative',
            textInputStyle === 'inline' ? 'inline-flex' : 'flex',
          )}
        >
          <div
            className={classNames(
              'relative items-stretch flex-grow focus-within:ring-2 rounded-md ring-offset-0',
              {
                'inline-flex': textInputStyle === 'inline',
                flex: textInputStyle !== 'inline',
                'focus-within:ring-red-500 focus-within:border-red-500':
                  errorMessage,
                'focus-within:ring-blue-600 focus-within:border-blue-600':
                  !errorMessage,
              },
            )}
          >
            {prefix && (
              <div className="sm:text-sm left-0 px-3 flex items-center pointer-events-none bg-gray-50 border border-gray-300 rounded-l-md text-gray-500">
                <span>{prefix}</span>
              </div>
            )}
            {icon && (
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <UsersIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            )}

            <input
              ref={(r) => {
                if (r) {
                  internalRef.current = r;
                }
              }}
              type={type}
              name={name}
              id={id}
              className={classNames(
                `sm:text-sm focus:outline-none focus:ring-0 focus:border-gray-300 ${
                  inputClassName || ''
                }`,
                {
                  'pr-10 border-red-300 text-red-900 placeholder-red-300':
                    errorMessage,
                  'placeholder-gray-500  sm:text-sm border-gray-300':
                    !errorMessage,
                  'disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200':
                    Boolean(disabled),
                  'rounded-md': !button && !prefix,
                  'rounded-none rounded-l-md': button && !prefix,
                  'rounded-none rounded-r-md border-l-0': prefix,
                  'pl-10': icon,
                  'block w-full': textInputStyle !== 'inline',
                },
              )}
              placeholder={placeholder}
              defaultValue={defaultValue}
              value={value}
              aria-invalid={Boolean(errorMessage)}
              aria-describedby={`${id}-error`}
              onKeyUp={onKeyUpProp ?? onKeyUp}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              onPaste={prefix ? onPaste : undefined}
            />
          </div>
          {button && (
            <button
              type="button"
              className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none"
              onClick={() => button.onClick?.(internalRef.current?.value)}
            >
              {button.component}
            </button>
          )}

          {Boolean(errorMessage) && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {optional && (
          <div className="text-sm mt-1.5 text-gray-500 text-normal">
            Optional
          </div>
        )}
        {Boolean(errorMessage) && (
          <ErrorText id={`${id}-error`} margin={optional ? 'mt-1' : undefined}>
            {errorMessage}
          </ErrorText>
        )}
      </div>
    );
  },
);

TextInput.defaultProps = {
  ref: undefined,
  defaultValue: undefined,
  placeholder: '',
  error: undefined,
  setHasError: undefined,
  type: 'text',
  icon: undefined,
  button: undefined,
  onChange: undefined,
  onKeyUp: undefined,
  disabled: false,
  validation: undefined,
  executeValidationOnBlur: false,
  value: undefined,
  textInputStyle: 'default',
  prefix: undefined,
  description: undefined,
  optional: undefined,
};

export default TextInput;
