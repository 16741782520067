/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetType,
    AssetTypeFromJSON,
    AssetTypeFromJSONTyped,
    AssetTypeToJSON,
} from './AssetType';
import {
    AudioSegment,
    AudioSegmentFromJSON,
    AudioSegmentFromJSONTyped,
    AudioSegmentToJSON,
} from './AudioSegment';
import {
    PreviewImages,
    PreviewImagesFromJSON,
    PreviewImagesFromJSONTyped,
    PreviewImagesToJSON,
} from './PreviewImages';
import {
    Shot,
    ShotFromJSON,
    ShotFromJSONTyped,
    ShotToJSON,
} from './Shot';
import {
    VideoAsset,
    VideoAssetFromJSON,
    VideoAssetFromJSONTyped,
    VideoAssetToJSON,
} from './VideoAsset';

/**
 * An image, video keyframe, or video
 * @export
 * @interface AssetResponse
 */
export interface AssetResponse {
    /**
     * 
     * @type {AssetType}
     * @memberof AssetResponse
     */
    assetType: AssetType;
    /**
     * The coactive image id (if the asset_type is 'image' or 'keyframe')
     * @type {string}
     * @memberof AssetResponse
     */
    coactiveImageId: string;
    /**
     * 
     * @type {PreviewImages}
     * @memberof AssetResponse
     */
    previewImages: PreviewImages;
    /**
     * The original path of the asset. This is the video path if the asset_type is 'keyframe'.
     * @type {string}
     * @memberof AssetResponse
     */
    path?: string | null;
    /**
     * 
     * @type {VideoAsset}
     * @memberof AssetResponse
     */
    video?: VideoAsset;
    /**
     * 
     * @type {Shot}
     * @memberof AssetResponse
     */
    shot?: Shot;
    /**
     * Flag for a sensitive content warning
     * @type {boolean}
     * @memberof AssetResponse
     */
    sensitive?: boolean | null;
    /**
     * 
     * @type {AudioSegment}
     * @memberof AssetResponse
     */
    audioSegment?: AudioSegment;
    /**
     * The user provided image id (if the asset_type is 'image')
     * @type {string}
     * @memberof AssetResponse
     */
    imageId?: string | null;
    /**
     * The file extension of the asset
     * @type {string}
     * @memberof AssetResponse
     */
    format?: string | null;
    /**
     * User provided metadata for the asset
     * @type {object}
     * @memberof AssetResponse
     */
    metadata?: object | null;
    /**
     * The created timestamp of the asset
     * @type {Date}
     * @memberof AssetResponse
     */
    createdDt: string;
    /**
     * The updated timestamp of the asset
     * @type {Date}
     * @memberof AssetResponse
     */
    updatedDt: string;
}

export function AssetResponseFromJSON(json: any): AssetResponse {
    return AssetResponseFromJSONTyped(json, false);
}

export function AssetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetType': AssetTypeFromJSON(json['assetType']),
        'coactiveImageId': json['coactiveImageId'],
        'previewImages': PreviewImagesFromJSON(json['previewImages']),
        'path': !exists(json, 'path') ? undefined : json['path'],
        'video': !exists(json, 'video') ? undefined : VideoAssetFromJSON(json['video']),
        'shot': !exists(json, 'shot') ? undefined : ShotFromJSON(json['shot']),
        'sensitive': !exists(json, 'sensitive') ? undefined : json['sensitive'],
        'audioSegment': !exists(json, 'audioSegment') ? undefined : AudioSegmentFromJSON(json['audioSegment']),
        'imageId': !exists(json, 'imageId') ? undefined : json['imageId'],
        'format': !exists(json, 'format') ? undefined : json['format'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'createdDt': json['createdDt'],
        'updatedDt': json['updatedDt'],
    };
}

export function AssetResponseToJSON(value?: AssetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assetType': AssetTypeToJSON(value.assetType),
        'coactiveImageId': value.coactiveImageId,
        'previewImages': PreviewImagesToJSON(value.previewImages),
        'path': value.path,
        'video': VideoAssetToJSON(value.video),
        'shot': ShotToJSON(value.shot),
        'sensitive': value.sensitive,
        'audioSegment': AudioSegmentToJSON(value.audioSegment),
        'imageId': value.imageId,
        'format': value.format,
        'metadata': value.metadata,
        'createdDt': value.createdDt,
        'updatedDt': value.updatedDt,
    };
}

