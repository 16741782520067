/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PagedLinksResponse,
    PagedLinksResponseFromJSON,
    PagedLinksResponseFromJSONTyped,
    PagedLinksResponseToJSON,
} from './PagedLinksResponse';
import {
    PagedPageDetailsResponse,
    PagedPageDetailsResponseFromJSON,
    PagedPageDetailsResponseFromJSONTyped,
    PagedPageDetailsResponseToJSON,
} from './PagedPageDetailsResponse';
import {
    SortItem,
    SortItemFromJSON,
    SortItemFromJSONTyped,
    SortItemToJSON,
} from './SortItem';

/**
 * Metadata about the paginated response
 * @export
 * @interface PagedMetaResponse
 */
export interface PagedMetaResponse {
    /**
     * 
     * @type {PagedPageDetailsResponse}
     * @memberof PagedMetaResponse
     */
    page: PagedPageDetailsResponse;
    /**
     * 
     * @type {PagedLinksResponse}
     * @memberof PagedMetaResponse
     */
    links: PagedLinksResponse;
    /**
     * Ordered list of fields to sort on, order of list determines priority
     * @type {Array<SortItem>}
     * @memberof PagedMetaResponse
     */
    sort: Array<SortItem>;
}

export function PagedMetaResponseFromJSON(json: any): PagedMetaResponse {
    return PagedMetaResponseFromJSONTyped(json, false);
}

export function PagedMetaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedMetaResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': PagedPageDetailsResponseFromJSON(json['page']),
        'links': PagedLinksResponseFromJSON(json['links']),
        'sort': ((json['sort'] as Array<any>).map(SortItemFromJSON)),
    };
}

export function PagedMetaResponseToJSON(value?: PagedMetaResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': PagedPageDetailsResponseToJSON(value.page),
        'links': PagedLinksResponseToJSON(value.links),
        'sort': ((value.sort as Array<any>).map(SortItemToJSON)),
    };
}

