import { DatasetStatusEnum, ValidateDatasetNameResponse } from 'api/generated';
import TextInput from 'components/TextInput';
import { useUserContext } from 'context/UserContext';
import { useAddAssetsNavigationContext } from 'pages/datasets/add-assets/context/AddAssetsNavigationContext';
import AddAssetsViewContainer from 'pages/datasets/components/AddAssetsViewContainer';
import {
  invalidateDatasetQueries,
  useCreateDatasetMutation,
  useGetDatasetById,
  useUpdateDatasetMutation,
  useValidateDatasetNameMutation,
} from 'queries/datasets';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * The main view of the "Name" step in the create dataset flow.
 */
const NameView = function NameView() {
  const { datasetId } = useParams();
  const { data: dataset } = useGetDatasetById(datasetId);
  const { setNext, setActiveStep } = useAddAssetsNavigationContext();
  const queryClient = useQueryClient();

  const { me } = useUserContext();
  const [name, setName] = useState<string>(dataset?.name ?? '');
  const [nameHasError, setNameHasError] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>();
  const [description, setDescription] = useState<string>(
    dataset?.description ?? '',
  );
  const { mutate: validateDatasetName, isLoading: isDatasetNameValidating } =
    useValidateDatasetNameMutation();
  const { mutate: createDataset, isLoading: isDatasetCreating } =
    useCreateDatasetMutation();
  const { mutate: updateDataset } = useUpdateDatasetMutation();
  const navigate = useNavigate();

  const inputValidation = useCallback(
    async (value: string) =>
      new Promise<ValidateDatasetNameResponse>((resolve, reject) => {
        if (value === dataset?.name) {
          resolve({ valid: { value: true }, unique: { value: true } });
        } else {
          validateDatasetName(
            { validateDatasetNameRequest: { name: value } },
            {
              onSuccess: (response) => {
                resolve(response);
              },
              onError: () => {
                reject();
              },
            },
          );
        }
      }),
    [dataset],
  );

  useEffect(() => {
    const action = () => {
      if (!name) {
        setSubmitError('Dataset name is required');
      } else if (name && !dataset) {
        createDataset(
          {
            createDatasetRequest: {
              name,
              description,
              status: DatasetStatusEnum.Incomplete,
            },
          },
          {
            onSuccess: (response) => {
              navigate(`/datasets/${response?.datasetId}/add-assets`, {
                replace: true,
              });
            },
            onError: async (error: any) => {
              setSubmitError((await error.response.json()).detail);
            },
          },
        );
      } else if (
        dataset &&
        (dataset.name !== name || dataset.description !== description)
      ) {
        updateDataset(
          {
            datasetId: dataset.datasetId,
            updateDatasetRequest: { name, description },
          },
          {
            onSuccess: (response) => {
              setActiveStep('Data Source');
              invalidateDatasetQueries(queryClient, response.datasetId);
            },
            onError: async (error: any) => {
              setSubmitError((await error.response.json()).detail);
            },
          },
        );
      } else {
        // No changes to an existing dataset - move to next step.
        setActiveStep('Data Source');
      }
    };

    const disabled =
      !name || isDatasetCreating || isDatasetNameValidating || nameHasError;

    setNext({ disabled, action });
  }, [
    dataset?.name,
    dataset?.description,
    name,
    description,
    isDatasetCreating,
    isDatasetNameValidating,
    nameHasError,
  ]);

  const orgName = me?.organization.displayName.replaceAll(
    /[^a-zA-Z0-9_]/gi,
    '',
  );
  return (
    <AddAssetsViewContainer
      title="Name your dataset"
      description="A name that gives a nice overview of what's in your dataset."
      isCreate
      error={submitError}
    >
      <div className="py-6">
        <TextInput
          label="Dataset name"
          id="dataset-name"
          name="dataset name"
          placeholder={`${orgName ? `${orgName}_` : ''}Coactive_trial`}
          value={name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
          validation={inputValidation}
          setHasError={setNameHasError}
        />
      </div>
      <div className="py-2">
        <TextInput
          label="Description"
          id="description"
          name="description"
          value={description}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setDescription(e.target.value)
          }
          executeValidationOnBlur
          optional
        />
      </div>
    </AddAssetsViewContainer>
  );
};

export default NameView;
