import classNames from 'classnames';
import React, {
  ForwardedRef,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';
import { ClipLoader } from 'react-spinners';
import colors from 'tailwindcss/colors';

export type ButtonStyle =
  | 'primary'
  | 'secondary'
  | 'soft'
  | 'link'
  | 'badge'
  | 'text';
export type ButtonSize = 'small' | 'default';

const DEFAULT_FOCUS_RING = 'focus:ring-2 focus:ring-offset-2';
const DEFAULT_DISABLED_TEXT_COLOR = 'disabled:text-gray-400';
const DEFAULT_SHADOW = 'shadow-sm';

const BUTTON_STYLES = {
  badge: {
    backgroundColor: 'bg-slate-100',
    backgroundHoverColor: 'hover:bg-slate-200',
    textColor: 'text-gray-800',
    textHoverColor: '',
    border: 'border-none rounded-md',
    focus: '',
    disabled: 'disabled:text-gray-400',
    shadow: 'shadow-none',
  },
  link: {
    backgroundColor: 'bg-transparent',
    backgroundHoverColor: 'hover:bg-emerald-50',
    textColor: 'text-emerald-600',
    textHoverColor: 'hover:text-green-600',
    border: 'border-none rounded-md',
    focus: `${DEFAULT_FOCUS_RING} focus:ring-emerald-700 focus:bg-emerald-50`,
    disabled: DEFAULT_DISABLED_TEXT_COLOR,
    shadow: 'shadow-none',
  },
  primary: {
    backgroundColor: 'bg-emerald-600',
    backgroundHoverColor: 'hover:bg-emerald-700',
    textColor: 'text-white',
    textHoverColor: '',
    border: 'border border-transparent rounded-md',
    focus: `${DEFAULT_FOCUS_RING} focus:ring-emerald-600`,
    disabled: `disabled:bg-gray-100 ${DEFAULT_DISABLED_TEXT_COLOR}`,
    shadow: DEFAULT_SHADOW,
  },
  secondary: {
    backgroundColor: 'bg-white',
    backgroundHoverColor: 'hover:bg-slate-50',
    textColor: 'text-slate-700',
    textHoverColor: '',
    border: 'border rounded-md border-slate-300',
    focus: `focus:ring-0 focus:bg-emerald-50 focus:border-emerald-600 focus:text-emerald-700`,
    disabled: `disabled:border-gray-200 ${DEFAULT_DISABLED_TEXT_COLOR}`,
    shadow: DEFAULT_SHADOW,
  },
  soft: {
    backgroundColor: 'bg-emerald-100',
    backgroundHoverColor: 'hover:bg-emerald-200',
    textColor: 'text-emerald-700',
    textHoverColor: '',
    border: 'border border-transparent rounded-md',
    focus: `${DEFAULT_FOCUS_RING} focus:ring-emerald-600`,
    disabled: `disabled:bg-gray-100 ${DEFAULT_DISABLED_TEXT_COLOR}`,
    shadow: DEFAULT_SHADOW,
  },
  text: {
    backgroundColor: '',
    backgroundHoverColor: '',
    textColor: 'text-emerald-700',
    textHoverColor: 'hover:underline',
    border: '',
    focus: '',
    disabled: `disabled:bg-gray-100 ${DEFAULT_DISABLED_TEXT_COLOR}`,
    shadow: '',
  },
};

function getTextColor(textColor?: string, style?: ButtonStyle) {
  if (textColor) {
    return textColor;
  }
  switch (style) {
    case 'link':
      return 'text-emerald-600';
    case 'secondary':
      return 'text-gray-500';
    case 'soft':
      return 'text-emerald-700';
    default:
      return 'text-white';
  }
}

function getTextHoverColor(textHoverColor?: string, style?: ButtonStyle) {
  if (textHoverColor) {
    return textHoverColor;
  }
  switch (style) {
    case 'link':
      return 'hover:text-green-600';
    default:
      return '';
  }
}

function getButtonStyles({
  variant = 'primary',
  size = 'default',
  color,
  hoverColor,
  textColor,
  textHoverColor,
  border,
  focus,
  shadow,
  padding,
  disabled,
}: {
  variant?: ButtonStyle;
  size?: ButtonSize;
  color?: string;
  hoverColor?: string;
  textColor?: string;
  textHoverColor?: string;
  border?: string;
  focus?: string;
  shadow?: string;
  padding?: string;
  disabled?: boolean;
}) {
  const variantStyles = BUTTON_STYLES[variant];

  const finalStyles = {
    backgroundColor: color || variantStyles.backgroundColor,
    backgroundHoverColor: !disabled
      ? hoverColor || variantStyles.backgroundHoverColor
      : '',
    textColor: textColor || variantStyles.textColor,
    textHoverColor: !disabled
      ? textHoverColor || variantStyles.textHoverColor
      : '',
    border: border || variantStyles.border,
    focus: focus || variantStyles.focus,
    shadow: shadow || variantStyles.shadow,
    padding: padding || (size === 'small' ? 'px-3 py-1' : 'px-4 py-2'),
    disabledStyle: disabled ? variantStyles.disabled : '',
  };

  return finalStyles;
}

interface InternalButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  border?: string;
  buttonStyle?: ButtonStyle;
  className?: string;
  color?: string;
  disabled?: boolean;
  focus?: string;
  hoverColor?: string;
  icon?: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
  iconColor?: string;
  id?: string;
  loading?: boolean;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
  padding?: string;
  rightIcon?: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
  shadow?: string;
  size?: ButtonSize;
  textColor?: string;
  textHoverColor?: string;
  textSize?: string;
  type?: 'submit' | 'button' | 'reset';
}

const Button = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<InternalButtonProps>
>(
  (
    {
      onClick,
      border,
      buttonStyle,
      className,
      children,
      color,
      disabled,
      focus,
      hoverColor,
      icon,
      id,
      loading,
      onMouseDown,
      padding,
      iconColor,
      rightIcon,
      shadow,
      size,
      textColor,
      textHoverColor,
      textSize,
      type,
    },
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const Icon = icon;
    const RightIcon = rightIcon;
    const styles = getButtonStyles({
      variant: buttonStyle,
      size,
      color,
      hoverColor,
      textColor,
      textHoverColor,
      border,
      focus,
      shadow,
      padding,
      disabled,
    });

    return (
      <button
        ref={ref}
        onClick={onClick}
        onMouseDown={onMouseDown}
        id={id}
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={classNames(
          'inline-flex items-center justify-center font-medium focus:outline-none transition-all relative',
          styles.backgroundColor,
          styles.border,
          styles.textColor,
          styles.focus,
          styles.padding || padding,
          styles.shadow,
          styles.backgroundHoverColor,
          styles.textHoverColor,
          {
            [styles.disabledStyle]: disabled,
          },
          textSize,
          className,
        )}
        disabled={disabled || loading}
      >
        {loading && (
          <ClipLoader
            cssOverride={{
              textAlign: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              margin: '-11px 0 0 -11px',
            }}
            color={
              buttonStyle === 'secondary' ? colors.gray['300'] : colors.white
            }
            loading
            size={22}
            speedMultiplier={0.75}
          />
        )}
        {Icon && (
          <Icon
            className={classNames(
              size === 'small' ? 'h-4 w-4' : '-ml-0.5 h-5 w-5',
              Boolean(children) && (size === 'small' ? 'mr-1.5' : 'mr-2'),
              loading ? 'invisible' : '',
              getTextColor(textColor, buttonStyle),
              getTextHoverColor(textHoverColor, buttonStyle),
            )}
          />
        )}
        {children ? (
          <div
            className={classNames('inline-flex items-center justify-center', {
              invisible: loading,
            })}
          >
            {children}
          </div>
        ) : undefined}
        {RightIcon && (
          <RightIcon
            className={classNames(
              size === 'small' ? 'h-4 w-4' : '-mr-0.5 h-5 w-5',
              Boolean(children) && (size === 'small' ? 'ml-1.5' : 'ml-2'),
              loading ? 'invisible' : '',
              iconColor || getTextColor(textColor, buttonStyle),
              iconColor || getTextHoverColor(textHoverColor, buttonStyle),
            )}
          />
        )}
      </button>
    );
  },
);

Button.defaultProps = {
  onMouseDown: undefined,
  disabled: false,
  className: undefined,
  buttonStyle: 'primary',
  color: undefined,
  hoverColor: undefined,
  icon: undefined,
  iconColor: undefined,
  rightIcon: undefined,
  padding: undefined,
  type: 'button',
  textColor: undefined,
  textHoverColor: undefined,
  textSize: 'text-sm',
  shadow: undefined,
  border: undefined,
  focus: undefined,
  size: 'default',
  id: undefined,
  loading: false,
};

export interface ButtonProps extends InternalButtonProps {}

export default Button;
