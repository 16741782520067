import { Column, ColumnDisplayType } from 'api/generated';
import classNames from 'classnames';
import ColumnFilters from 'components/ColumnFilters';
import EmptyStateMessage from 'components/EmptyStateMessage';
import Pagination from 'components/Pagination/index';
import React, { ReactElement, useState } from 'react';
import { TablePageSize } from 'types/table';
import ProtectedFileDownload from 'components/ProtectedFileDownload';
import TableBodyRow from 'components/Table/TableBodyRow';
import { TableAction, TableRow } from 'components/Table/types';

const DEFAULT_PAGE_SIZE: TablePageSize = 50;

interface TableProps<T extends TableRow> {
  columns: Column[];
  data: T[];
  // The key of the unique identifier for each row. Ex. for concepts, it's 'concept_id'.
  idKey: string;
  actions?: TableAction<T>[];
  loadStrategy?: 'paginated' | 'scroll';
  total: number;
  pageSize?: TablePageSize;
  setPageSize?: (size: TablePageSize) => void;
  activePage?: number;
  setActivePage?: (size: number) => void;
  loadPage?: (page: number, pageSize: TablePageSize) => void;
  showRowCount?: boolean;
  downloadCsvUrl?: string | (() => Promise<string>);
  downloadCsvFilename?: string | ((url: string) => string);
  downloadCsvUrlAuthenticated?: boolean;
  emptyMessage?: string | ReactElement;
  getStatusClassName?: (status: string) => string;
  onClickImagePreview?: (data: TableRow) => void;
  filters?: { [key: string]: ReactElement<any, any> | undefined };
  loading?: boolean;
  hiddenColumns?: Set<string>;
  setHiddenColumns?: (hiddenColumns: Set<string>) => void;
}

const Table = function Table<T extends TableRow>({
  columns,
  data,
  idKey,
  actions,
  loadStrategy,
  total,
  activePage,
  setActivePage,
  pageSize,
  setPageSize,
  loadPage,
  showRowCount,
  downloadCsvUrl,
  downloadCsvFilename,
  downloadCsvUrlAuthenticated,
  emptyMessage,
  getStatusClassName,
  hiddenColumns,
  setHiddenColumns,
  onClickImagePreview,
  filters,
  loading,
}: TableProps<T>) {
  const [scrolled, setScrolled] = useState(false);

  const onChange = ({ key, checked }) => {
    const updated = new Set(hiddenColumns);
    if (!checked) {
      updated.add(key);
    } else {
      updated.delete(key);
    }
    setHiddenColumns?.(updated);
  };

  const showStickyRowCount =
    !hiddenColumns?.has('row') &&
    Boolean(columns?.find((col) => col.key === 'row'));
  const totalRowCount = total || 0;
  return (
    <div className="flex flex-col w-full">
      <div className="w-full flex-1 overflow-y-visible min-h-[26rem]">
        <div className="flex items-center">
          {showRowCount && (
            <div className="font-medium text-sm p-2 pl-0">
              {totalRowCount.toLocaleString()}{' '}
              {totalRowCount === 1 ? 'row' : 'rows'}
            </div>
          )}
          {hiddenColumns && (
            <div className="flex p-2 items-center ml-auto">
              <ColumnFilters
                columns={columns}
                hiddenColumns={hiddenColumns}
                onChange={onChange}
              />
            </div>
          )}
          {downloadCsvUrl && (
            <div
              className={classNames(
                'my-1',
                !hiddenColumns ? 'ml-auto' : 'ml-1',
              )}
            >
              <ProtectedFileDownload
                url={downloadCsvUrl}
                filename={downloadCsvFilename}
                useAuth={downloadCsvUrlAuthenticated}
              />
            </div>
          )}
        </div>
        <div className="overflow-x-hidden overflow-y-visible bg-white sm:rounded-lg w-full border border-gray-200 shadow-md">
          <div className="flex relative">
            {showStickyRowCount && (
              <div
                className={classNames(
                  'sticky grow-0 divide-y divide-gray-200 ',
                  {
                    'shadow-md': scrolled,
                  },
                )}
              >
                <div className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider max-w-xs sticky bg-gray-50">
                  Row
                </div>
                {data?.map((row) => (
                  <div
                    key={row.row}
                    className={classNames(
                      'px-4 py-3 text-left text-sm font-medium font-medium text-gray-500 uppercase tracking-wider max-w-xs text-center ',
                    )}
                  >
                    {row.row + 1}
                  </div>
                ))}
              </div>
            )}
            <div
              onScroll={
                showStickyRowCount
                  ? (e) => {
                      const scrollLeft = (e.target as any)?.scrollLeft;
                      if (
                        typeof scrollLeft === 'number' &&
                        scrollLeft > 5 &&
                        !scrolled
                      ) {
                        setScrolled(true);
                      } else if (
                        typeof scrollLeft === 'number' &&
                        scrollLeft <= 5 &&
                        scrolled
                      ) {
                        setScrolled(false);
                      }
                    }
                  : undefined
              }
              className="overflow-x-scroll overscroll-x-contain w-full inline-block grow"
            >
              <table
                className={classNames(
                  'min-w-full divide-y divide-gray-200',
                  showStickyRowCount && 'pl-[4rem]',
                )}
              >
                <thead className="bg-gray-50">
                  <tr>
                    {columns
                      ?.filter((col) => !hiddenColumns?.has(col.key))
                      .map((col: Column) => {
                        if (
                          col.displayType === ColumnDisplayType.Action &&
                          col.hideLabel
                        ) {
                          return (
                            <th
                              key={col.key}
                              scope="col"
                              className="relative px-4 py-3 max-w-xs"
                            >
                              <span className="sr-only">
                                {col.label || col.key}
                              </span>
                            </th>
                          );
                        }

                        return (
                          <th
                            key={col.key}
                            scope="col"
                            className={classNames(
                              'px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider max-w-xs ',
                              col.key === 'row' && 'sr-only',
                            )}
                          >
                            <div className="flex items-center relative">
                              {col.label || col.key}
                              {filters?.[col.key]}
                            </div>
                          </th>
                        );
                      })}
                    {actions ? (
                      <th
                        key="actions"
                        scope="col"
                        className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-12"
                      >
                        <span className="sr-only">Actions</span>
                      </th>
                    ) : undefined}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data?.map((row) => (
                    <TableBodyRow
                      className={classNames([
                        'transition-all',
                        loading ? 'opacity-50' : '',
                      ])}
                      key={row.data[idKey] ?? `row-{${row.row}}`}
                      row={row}
                      idKey={idKey}
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      actions={actions}
                      getStatusClassName={
                        getStatusClassName ?? (() => 'text-slate-500')
                      }
                      onClickImagePreview={onClickImagePreview}
                    />
                  ))}
                </tbody>
              </table>
              {!data.length &&
                (typeof emptyMessage === 'string' ? (
                  <div className="flex items-center justify-center px-4 py-3 h-32">
                    <EmptyStateMessage>{emptyMessage}</EmptyStateMessage>
                  </div>
                ) : (
                  emptyMessage
                ))}
            </div>
          </div>
          {loadStrategy === 'paginated' && pageSize && total > pageSize ? (
            <div className="bg-white border-t border-gray-200 px-4 py-3 sm:px-6">
              <Pagination
                activePage={activePage || 1}
                total={total}
                pageSize={pageSize}
                setPageSize={setPageSize}
                goToPage={(page: number) => {
                  loadPage?.(page, pageSize);
                  setActivePage?.(page);
                }}
              />
            </div>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

Table.defaultProps = {
  actions: undefined,
  loadStrategy: 'paginated',
  pageSize: DEFAULT_PAGE_SIZE,
  activePage: 1,
  showRowCount: false,
  setPageSize: undefined,
  setActivePage: undefined,
  loadPage: undefined,
  downloadCsvUrl: undefined,
  downloadCsvFilename: undefined,
  downloadCsvUrlAuthenticated: true,
  emptyMessage: 'No data to show',
  getStatusClassName: undefined,
  hiddenColumns: undefined,
  setHiddenColumns: undefined,
  onClickImagePreview: undefined,
  filters: undefined,
  loading: false,
};

export default Table;
