import Button from 'components/Button';
import React from 'react';
import { ClipLoader } from 'react-spinners';
import colors from 'tailwindcss/colors';
import { MultiStepFlowAction } from 'types/action';

interface ToolbarButtonProps extends MultiStepFlowAction {
  loading?: boolean;
}

const ToolbarButton: React.FC<ToolbarButtonProps> = function ToolbarButton({
  action,
  disabled,
  label,
  leftIcon,
  loading,
  loadingMessage,
  rightIcon,
}) {
  if (!action) {
    return null;
  }
  return (
    <div className="inline-flex items-center">
      {loading && loadingMessage && (
        <span className="flex items-center text-sm text-gray-400 pr-4">
          <span className="pr-2 flex items-center">
            <ClipLoader
              color={colors.gray['300']}
              loading
              size={22}
              speedMultiplier={0.75}
            />
          </span>
          {loadingMessage}
        </span>
      )}
      <Button
        onClick={action}
        disabled={disabled || loading}
        icon={leftIcon}
        rightIcon={rightIcon}
        buttonStyle="secondary"
      >
        {label}
      </Button>
    </div>
  );
};

ToolbarButton.defaultProps = {
  loading: false,
};

export default ToolbarButton;
