/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FullConceptResponseUI,
    FullConceptResponseUIFromJSON,
    FullConceptResponseUIFromJSONTyped,
    FullConceptResponseUIToJSON,
} from './FullConceptResponseUI';
import {
    PagedMetaResponse,
    PagedMetaResponseFromJSON,
    PagedMetaResponseFromJSONTyped,
    PagedMetaResponseToJSON,
} from './PagedMetaResponse';

/**
 * A response containing paged data
 * @export
 * @interface FullConceptListResponseUI
 */
export interface FullConceptListResponseUI {
    /**
     * 
     * @type {PagedMetaResponse}
     * @memberof FullConceptListResponseUI
     */
    meta: PagedMetaResponse;
    /**
     * The paginated concepts
     * @type {Array<FullConceptResponseUI>}
     * @memberof FullConceptListResponseUI
     */
    data: Array<FullConceptResponseUI>;
}

export function FullConceptListResponseUIFromJSON(json: any): FullConceptListResponseUI {
    return FullConceptListResponseUIFromJSONTyped(json, false);
}

export function FullConceptListResponseUIFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullConceptListResponseUI {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': PagedMetaResponseFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(FullConceptResponseUIFromJSON)),
    };
}

export function FullConceptListResponseUIToJSON(value?: FullConceptListResponseUI | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': PagedMetaResponseToJSON(value.meta),
        'data': ((value.data as Array<any>).map(FullConceptResponseUIToJSON)),
    };
}

