import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { PaginationState } from 'types/pagination';
import { TablePageSize } from 'types/table';

const DEFAULT_RESULTS_PER_PAGE: TablePageSize = 50;

interface PaginationHook {
  page: number;
  size: TablePageSize;
  setPage: (newPage: number) => void;
  setSize: (newSize: TablePageSize) => void;
  reset: () => void;
}

export const usePagination = (
  defaultPageSize: TablePageSize = DEFAULT_RESULTS_PER_PAGE,
  pageKey = 'page',
  sizeKey = 'size',
): PaginationHook => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: parseInt(searchParams.get(pageKey) || '1', 10),
    size: parseInt(
      searchParams.get(sizeKey) || defaultPageSize.toString(),
      10,
    ) as TablePageSize,
  });
  const setNewParams = (paramsToSet: Record<string, string>) => {
    const newParams: { [key: string]: string } = {};
    searchParams.forEach((value: string, key: string) => {
      newParams[key] = value;
    });

    setSearchParams({ ...newParams, ...paramsToSet });
  };
  const setNewPage = (newPage: number) => {
    setPaginationState({ ...paginationState, page: newPage });
  };
  useEffect(() => {
    const pageQueryParam = parseInt(searchParams.get(pageKey) || '', 10);
    const sizeQueryParam = parseInt(searchParams.get(sizeKey) || '', 10);
    const { size, page } = paginationState;
    if (pageQueryParam !== page || sizeQueryParam !== size) {
      if (Number.isNaN(pageQueryParam)) {
        setNewParams({
          [pageKey]: '1',
          [sizeKey]: paginationState.size.toString(),
        });
        setNewPage(1);
      } else {
        setNewParams({
          [pageKey]: paginationState.page.toString(),
          [sizeKey]: paginationState.size.toString(),
        });
      }
    }
  }, [paginationState, location]);
  const setNewSize = (newSize: TablePageSize) => {
    const newPage = 1;
    setPaginationState({ size: newSize, page: newPage });
  };
  const reset = useCallback(() => {
    const newSize = defaultPageSize;
    const newPage = 1;
    setPaginationState({ size: newSize, page: newPage });
  }, []);
  const value = useMemo(
    () => ({
      ...paginationState,
      setPage: setNewPage,
      setSize: setNewSize,
      reset,
    }),
    [paginationState],
  );
  return value;
};

export default usePagination;
