/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Metadata about a video
 * @export
 * @interface VideoAsset
 */
export interface VideoAsset {
    /**
     * The URL of the asset
     * @type {string}
     * @memberof VideoAsset
     */
    url: string;
    /**
     * The unique video id
     * @type {string}
     * @memberof VideoAsset
     */
    id: string;
    /**
     * The start time in milliseconds from the beginning of the video. Deprecation warning - use 'shot.start_time_ms'.
     * @type {number}
     * @memberof VideoAsset
     * @deprecated
     */
    startTimeMs: number;
    /**
     * The end time in milliseconds from the beginning of the video. Deprecation warning - use 'shot.end_time_ms'.
     * @type {number}
     * @memberof VideoAsset
     * @deprecated
     */
    endTimeMs: number;
}

export function VideoAssetFromJSON(json: any): VideoAsset {
    return VideoAssetFromJSONTyped(json, false);
}

export function VideoAssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoAsset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'id': json['id'],
        'startTimeMs': json['startTimeMs'],
        'endTimeMs': json['endTimeMs'],
    };
}

export function VideoAssetToJSON(value?: VideoAsset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'id': value.id,
        'startTimeMs': value.startTimeMs,
        'endTimeMs': value.endTimeMs,
    };
}

