import { MeResponse, Permissions } from 'api/generated';
import { useUserContext } from 'context/UserContext';
import { useMemo } from 'react';

export interface Toggles {
  searchThumbnails: boolean;
  generateTrailer: boolean;
  dynamicTagging: boolean;
}

interface FeatureToggle {
  allowedOrgIds?: (string | undefined)[];
  requiredPermissions?: Permissions[];
}

const SEARCH_THUMBNAILS: FeatureToggle = {
  allowedOrgIds: [
    'org_AHqxz0XDRvEVg7P2', // coactive-dev (non-production)
    'org_QanuVpZNZwrM93JG', // coactive-dev (production)
    'org_poSBndpMbveNRppF', // comcast (production)
  ],
};

const GENERATE_TRAILER: FeatureToggle = {
  allowedOrgIds: [
    'org_AHqxz0XDRvEVg7P2', // coactive-dev (non-production)
    'org_QanuVpZNZwrM93JG', // coactive-dev (production)
    'org_poSBndpMbveNRppF', // comcast (production)
  ],
  requiredPermissions: [Permissions.Featuresinternal],
};

// TODO: determine if feature is on based on LaunchDarkly configs
const DYNAMIC_TAGGING: FeatureToggle = {
  requiredPermissions: [Permissions.Featuresinternal],
};

function isFeatureToggleOn(ft: FeatureToggle, me: MeResponse | undefined) {
  return (
    (!ft.allowedOrgIds || ft.allowedOrgIds.includes(me?.organization.orgId)) &&
    (!ft.requiredPermissions ||
      ft.requiredPermissions.every((p) => me?.permissions.includes(p)))
  );
}

const useToggles = (): Toggles => {
  const { me } = useUserContext();
  return useMemo(
    () => ({
      searchThumbnails: isFeatureToggleOn(SEARCH_THUMBNAILS, me),
      generateTrailer: isFeatureToggleOn(GENERATE_TRAILER, me),
      dynamicTagging: isFeatureToggleOn(DYNAMIC_TAGGING, me),
    }),
    [me],
  );
};

export default useToggles;
