/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PagedMetaResponse,
    PagedMetaResponseFromJSON,
    PagedMetaResponseFromJSONTyped,
    PagedMetaResponseToJSON,
} from './PagedMetaResponse';
import {
    VersionResponse,
    VersionResponseFromJSON,
    VersionResponseFromJSONTyped,
    VersionResponseToJSON,
} from './VersionResponse';

/**
 * A response containing paged data
 * @export
 * @interface GetVersionListResponse
 */
export interface GetVersionListResponse {
    /**
     * 
     * @type {PagedMetaResponse}
     * @memberof GetVersionListResponse
     */
    meta: PagedMetaResponse;
    /**
     * List of versions
     * @type {Array<VersionResponse>}
     * @memberof GetVersionListResponse
     */
    data: Array<VersionResponse>;
}

export function GetVersionListResponseFromJSON(json: any): GetVersionListResponse {
    return GetVersionListResponseFromJSONTyped(json, false);
}

export function GetVersionListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetVersionListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': PagedMetaResponseFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(VersionResponseFromJSON)),
    };
}

export function GetVersionListResponseToJSON(value?: GetVersionListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': PagedMetaResponseToJSON(value.meta),
        'data': ((value.data as Array<any>).map(VersionResponseToJSON)),
    };
}

