import classNames from 'classnames';
import React from 'react';
import Check from 'assets/icons/check-white.svg';

interface SelectedIconProps {
  selected: boolean;
  order?: number;
}

const SelectedIcon: React.FunctionComponent<SelectedIconProps> =
  function SelectedIcon({ selected, order }) {
    return (
      <div
        className={classNames(
          'h-8 w-8 rounded-full m-1 flex items-center justify-center absolute top-0 right-0 box-border transition-all',
          {
            'bg-blue-500': selected,
            'border-2 border-blue-500/75': !selected,
          },
        )}
      >
        {selected && order !== undefined && (
          <span className="font-bold text-white">{order + 1}</span>
        )}
        {selected && order === undefined && (
          <img src={Check} alt="selected" className="w-3/5 fill-white" />
        )}
      </div>
    );
  };

SelectedIcon.defaultProps = {
  order: undefined,
};

export default SelectedIcon;
