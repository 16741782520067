import {
  AssetResponse,
  DatasetUpdateResponse,
  FullDatasetResponse,
  QueryTableRowUI,
} from 'api/generated';
import { SearchMode } from 'components/DatasetSearch/AdvancedSearch/types';
import DatasetSearch from 'components/DatasetSearch/index';
import { DropdownOption } from 'components/Dropdown';
import FadeTransition from 'components/FadeTransition';
import { useSidebarAssetContext } from 'context/SidebarAssetContext';
import usePopupPadding from 'hooks/usePopupPadding';
import DatasetImages from 'pages/datasets/detail/components/DatasetView/DatasetImages';
import IncompleteDatasetUpdates from 'pages/datasets/detail/components/DatasetView/DatasetPendingUpdates';
import DatasetVideos from 'pages/datasets/detail/components/DatasetView/DatasetVideos';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AssetTypeOption } from 'components/TextSearch';
import EmptyStateMessage from 'components/EmptyStateMessage';

enum DatasetViewTab {
  images = 'images',
  videos = 'videos',
}

interface DatasetViewProps {
  dataset: FullDatasetResponse | undefined;
  datasetId: string | undefined;
  incompleteDatasetUpdates: DatasetUpdateResponse[] | undefined;
  loadingGrid: any;
  searchActive: boolean;
  searchMode: SearchMode;
  searchModeOptions: DropdownOption<SearchMode>[];
  setSearchMode: (mode: SearchMode) => void;
  setSelectedAsset: (selected: {
    asset?: AssetResponse;
    data?: QueryTableRowUI;
  }) => void;
  setTextSearchActive: (active: boolean) => void;
}

const DatasetView: React.FC<DatasetViewProps> = function DatasetView({
  dataset,
  datasetId,
  incompleteDatasetUpdates,
  loadingGrid,
  searchActive,
  searchMode,
  searchModeOptions,
  setSearchMode,
  setSelectedAsset,
  setTextSearchActive,
}: DatasetViewProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const extraSpaceRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const TAB_QUERY_PARAM = 'tab';
  const { setPopupHeight } = usePopupPadding(containerRef, extraSpaceRef);
  const { setSelectedAsset: setSidebarAsset } = useSidebarAssetContext();
  // temporary behavior to switch between endpoints in order to remove tabs
  const [isUsingSearchEndpoint, setIsUsingSearchEndpoint] =
    useState<boolean>(false);
  const [datasetViewAssetType, setDatasetViewAssetType] =
    useState<AssetTypeOption>('All content');
  useEffect(() => {
    if (dataset?.imageCount === 0 && dataset?.videoCount) {
      setSearchParams({ [TAB_QUERY_PARAM]: DatasetViewTab.videos });
    }
  }, [dataset]);

  const imageCount = dataset?.imageCount || 0;
  const videoCount = dataset?.videoCount || 0;

  const activeTab = useMemo(() => {
    const tab = searchParams.get('tab');
    if (tab && DatasetViewTab[tab]) {
      return DatasetViewTab[tab];
    }
    return DatasetViewTab.images;
  }, [searchParams]);

  const handleSelectAsset = (asset: AssetResponse) => {
    setSidebarAsset(asset);
    setSelectedAsset({ asset });
  };

  return dataset ? (
    <>
      {Boolean(incompleteDatasetUpdates?.length) && (
        <IncompleteDatasetUpdates updates={incompleteDatasetUpdates!} />
      )}
      <div ref={containerRef}>
        {dataset.isSearchable ? (
          <DatasetSearch
            setIsUsingSearchEndpoint={setIsUsingSearchEndpoint}
            searchModeOptions={searchModeOptions}
            key={activeTab}
            searchMode={searchMode}
            setSearchMode={setSearchMode}
            defaultAssetType="All content"
            datasetId={datasetId || ''}
            onClick={(asset) => handleSelectAsset(asset)}
            setTextSearchActive={setTextSearchActive}
            setPopupHeight={setPopupHeight}
            searchContainerClassName="mb-4"
            setDatasetViewAssetType={setDatasetViewAssetType}
            datasetViewAssetType={datasetViewAssetType}
            isUsingSearchEndpoint={isUsingSearchEndpoint}
            searchActive={searchActive}
          />
        ) : undefined}

        {datasetViewAssetType === 'All content' && !searchActive && (
          <>
            <p className="text-sm text-gray-500 my-4 font-bold">
              {imageCount} images / {videoCount} videos
            </p>
            {imageCount > 0 && (
              <DatasetImages
                datasetId={datasetId || ''}
                loadingView={loadingGrid}
                onClick={(asset) => handleSelectAsset(asset)}
                hideCount
              />
            )}
            {videoCount > 0 && (
              <DatasetVideos
                datasetId={datasetId || ''}
                loadingView={loadingGrid}
                onClick={(asset) => handleSelectAsset(asset)}
                hideCount
              />
            )}
            {imageCount === 0 && videoCount === 0 && (
              <div className="text-center py-12 px-2 text-sm">
                <EmptyStateMessage>
                  <p>No assets in dataset</p>
                </EmptyStateMessage>
              </div>
            )}
          </>
        )}
        {datasetViewAssetType === 'Images' && !searchActive && (
          <DatasetImages
            datasetId={datasetId || ''}
            loadingView={loadingGrid}
            onClick={(asset) => handleSelectAsset(asset)}
          />
        )}
        {datasetViewAssetType === 'Videos' && !searchActive && (
          <DatasetVideos
            datasetId={datasetId || ''}
            loadingView={loadingGrid}
            onClick={(asset) => handleSelectAsset(asset)}
          />
        )}
      </div>
      <div ref={extraSpaceRef} />
    </>
  ) : (
    <FadeTransition
      appear
      show={!dataset && !searchActive}
      exit={false}
      enterDelayMs={500}
    >
      {!dataset && loadingGrid}
    </FadeTransition>
  );
};

export default DatasetView;
