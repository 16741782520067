import TextInput from 'components/TextInput';
import React, { ChangeEvent, useCallback } from 'react';

interface S3DataSourceInputProps {
  defaultValue: string | null | undefined;
  setPrefix: (prefix: string) => void;
}

/**
 * The input component for S3 prefixes.
 */
const S3DataSourceInput = function S3DataSourceInput({
  defaultValue,
  setPrefix,
}: S3DataSourceInputProps) {
  const validateBucketPath = useCallback((value: string) => {
    const regex = /^s3:\/\/([^/]+)((\/(.*?([^/]+)))*(\/?))$/;
    const valid = !value || regex.test(value);
    return Promise.resolve({
      valid: {
        value: valid,
        message: valid ? undefined : 'The provided S3 prefix is not valid',
      },
      unique: { value: true },
    });
  }, []);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPrefix(e.target.value);
  }, []);

  return (
    <TextInput
      label="Amazon Web Services S3 bucket prefix"
      id="bucket-path"
      name="bucket path"
      placeholder="bucket-name/path/prefix/"
      onChange={onChange}
      validation={validateBucketPath}
      prefix="s3://"
      defaultValue={defaultValue ?? ''}
    />
  );
};

export default S3DataSourceInput;
