import {
  MagnifyingGlassIcon,
  PhotoIcon,
  TableCellsIcon,
} from '@heroicons/react/24/solid';
import { FullConceptVersionLabelResponse } from 'api/generated';
import Button from 'components/Button';
import DatasetSearch from 'components/DatasetSearch/index';
import PillTabs, { Tab } from 'components/PillTabs';
import FindSimilarImages from 'pages/concepts/detail/tabs/Examples/AddExamples/FindSimilarImages/index';
import UploadLabelsCsv from 'pages/concepts/detail/tabs/Examples/AddExamples/UploadLabelsCsv';
import { useGetLabelsForVersionInfiniteQuery } from 'pages/concepts/queries';
import React, { useCallback, useMemo, useState } from 'react';

const SEARCH_DATASET = 'Search Dataset';
const FIND_SIMILAR_IMAGES_IN_DATASET = 'Find Similar Images in Dataset';
const UPLOAD_LABELS_CSV = 'Upload Labels CSV';

const tabs: Tab[] = [
  {
    name: SEARCH_DATASET,
    icon: MagnifyingGlassIcon,
  },
  {
    name: FIND_SIMILAR_IMAGES_IN_DATASET,
    icon: PhotoIcon,
  },
  {
    name: UPLOAD_LABELS_CSV,
    icon: TableCellsIcon,
  },
];

interface AddExamplesProps {
  conceptId: string;
  conceptVersionId: string;
  conceptVersionPositiveLabelsCount: number;
  datasetId: string;
  addLabels: (positive: string[], negative: string[]) => void;
  isAddingLabels: boolean;
  exit: (refreshLabels?: boolean) => void;
  setShouldRefetchLabels?: (shouldRefetchLabels: boolean) => void;
}

const AddExamples: React.FC<AddExamplesProps> = function AddExamples({
  conceptId,
  datasetId,
  conceptVersionId,
  conceptVersionPositiveLabelsCount,
  addLabels,
  isAddingLabels,
  exit,
  setShouldRefetchLabels,
}) {
  const [activeTab, setActiveTab] = useState<string>(SEARCH_DATASET);
  const { data } = useGetLabelsForVersionInfiniteQuery(conceptVersionId);

  const [, totalExamples] = useMemo(
    () => [
      data?.pages.reduce(
        (acc: FullConceptVersionLabelResponse[], item) => [
          ...acc,
          ...item.data,
        ],
        [],
      ) || [],
      data?.pages.length
        ? data.pages[data.pages.length - 1].meta?.page?.total
        : 0,
    ],
    [data],
  );

  const exitWithoutChanges = useCallback(() => {
    exit(false);
    setShouldRefetchLabels?.(true);
  }, [exit]);

  return (
    <div className="relative w-full my-4">
      {totalExamples ? (
        <div className="relative lg:absolute text-center top-0 right-0">
          <Button buttonStyle="secondary" onClick={exitWithoutChanges}>
            Done
          </Button>
        </div>
      ) : undefined}
      <PillTabs
        className="text-center pt-4 lg:py-0"
        tabs={tabs}
        active={activeTab}
        onClick={setActiveTab}
      />
      <div className="pt-8">
        {activeTab === SEARCH_DATASET ? (
          <DatasetSearch
            datasetId={datasetId || ''}
            labelResults={addLabels}
            isLabelingResults={isAddingLabels}
            hideHeader
            searchMode="visual"
            defaultAssetType="All content"
            searchContainerClassName="max-w-5xl mx-auto my-4"
            searchModeOptions={[
              {
                label: 'Visual search',
                value: 'visual',
              },
            ]}
          />
        ) : undefined}
        {activeTab === FIND_SIMILAR_IMAGES_IN_DATASET && (
          <FindSimilarImages
            conceptId={conceptId}
            conceptVersionId={conceptVersionId}
            addLabels={addLabels}
            isAddingLabels={isAddingLabels}
            conceptVersionPositiveLabelsCount={
              conceptVersionPositiveLabelsCount
            }
            exit={exit}
          />
        )}
        {activeTab === UPLOAD_LABELS_CSV && (
          <UploadLabelsCsv
            conceptId={conceptId}
            conceptVersionId={conceptVersionId}
            exit={exit}
          />
        )}
      </div>
    </div>
  );
};

AddExamples.defaultProps = {
  setShouldRefetchLabels: undefined,
};

export default AddExamples;
