import React, { PropsWithChildren } from 'react';

const EmptyStateMessage: React.FC<PropsWithChildren<{}>> =
  function EmptyStateMessage({ children }) {
    return (
      <div className="text-sm font-medium font-medium text-gray-400 uppercase tracking-wider">
        {children}
      </div>
    );
  };

export default EmptyStateMessage;
